import React, { useEffect } from "react";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  HomeIcon,
  HomeIconActive,
  MarketplaceHomeActiveIcon,
  MarketPlaceHomeIcon,
  CoinIcon,
  CoinIconActive,
  NFTIcon,
  NFTIconActive,
} from "../../assets/icons/menu-icons/MenuIcons";
import useNavigationHook from "../../hooks/useNavigation";
import { ReactComponent as ABCLogo } from "../../assets/icons/navbar_abc_logo.svg";
import { ReactComponent as PointIcon } from "../../assets/icons/menu-icons/point_icon.svg";
import { ReactComponent as PointIconActive } from "../../assets/icons/menu-icons/point_icon_active.svg";
import PointIconPng from "../../assets/icons/menu-icons/point_icon.png";
import PointIconActivePng from "../../assets/icons/menu-icons/point_icon_active.png";
import { isFusion } from "../../config";
import SettingBtn from "pages/settings/SettingBtn";
import useDrawerMenu from "hooks/useDrawerMenu";
export const HOME = "Home";
export const COIN = "ABC Coin";
export const POINT = "ABC Point";
export const MARKET = "Marketplace";
export const NFT = "NFT";
export const MORE = "More";
export const menuList = [
  {
    name: HOME,
    src: <HomeIcon />,
    srcActive: <HomeIconActive />,
    href: "/",
  },
  {
    name: isFusion ? POINT : COIN,
    src: isFusion ? (
      <PointIcon data-testid="point-icon" />
    ) : (
      <CoinIcon data-testid="coin-icon" />
    ),
    srcActive: isFusion ? <PointIconActive /> : <CoinIconActive />,
    href: "/wallet",
  },
  {
    name: MARKET,
    src: <MarketPlaceHomeIcon />,
    srcActive: <MarketplaceHomeActiveIcon />,
    href: "/marketplace",
  },
  {
    name: NFT,
    src: <NFTIcon />,
    srcActive: <NFTIconActive />,
    href: "/wallet/nfts",
  },
];

// Input the links for final urls should be the same with icons' href
export const activeIcon = icon => {
  const { pathname } = window.location;
  switch (icon) {
    case HOME:
      if (pathname === "/") {
        return true;
      }
      break;
    case NFT:
      if (
        pathname === "/wallet/nfts" ||
        pathname === "/wallet/nfts/other-collectibles"
      ) {
        return true;
      }
      break;
    case COIN:
      if (pathname === "/wallet/nfts") {
        return false;
      }
      if (pathname.match(/\/wallet*/)) {
        return true;
      }
      break;
    case POINT:
      if (
        pathname === "/wallet/nfts" ||
        pathname === "/wallet/nfts/other-collectibles"
      ) {
        return false;
      }
      if (pathname.match(/\/wallet*/)) {
        return true;
      }
      break;
    case MARKET:
      if (pathname.match(/\/marketplace*/)) {
        return true;
      }
      break;
    default:
      return false;
  }
};

const MenuIcon = ({ icon, handleMenuClick }) => {
  return (
    <IconButton
      data-testid={`${icon.name}`}
      sx={{
        borderRadius: 24,
        "&:hover": {
          backgroundColor: activeIcon(icon.name)
            ? "secondary.pink.accent4"
            : "",
        },
        "&:active": {
          backgroundColor: "primary.light",
        },
      }}
      onClick={e => {
        e.preventDefault();
        handleMenuClick(icon.href);
      }}
    >
      {activeIcon(icon.name) ? icon.srcActive : icon.src}
    </IconButton>
  );
};

const Menu = ({ active, level, activeDrawerMenu }) => {
  const setActiveMenu = useNavigationHook();
  const setActiveDrawerMenu = useDrawerMenu();
  const navigate = useNavigate();
  const utheme = useTheme();
  const matchDownMD = useMediaQuery(utheme.breakpoints.down("md"));

  const handleMenuClick = href => {
    setActiveDrawerMenu(false);
    navigate(href);
  };

  useEffect(() => {
    window.onpopstate = () => {
      setActiveMenu(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!active || (level !== 1 && matchDownMD)) return "";
  return (
    <Box
      id="side-menu"
      data-testid="side-menu"
      sx={{
        zIndex: 1100,
        gridArea: "menu",
        display: "flex",
        flexDirection: {
          xs: "row",
          sm: "row",
          md: "column",
          lg: "column",
          xl: "column",
        },

        justifyContent: {
          xs: "space-around",
          sm: "space-around",
          md: "normal",
          lg: "normal",
          xl: "normal",
        },
        alignItems: "center",
        gap: {
          md: 3,
          lg: 3,
          xl: 3,
        },
        paddingY: {
          xs: "14.54px",
          sm: "14.54px",
          md: 0,
          lg: 0,
          xl: 0,
        },
        backgroundColor: "#FFF6F6",
      }}
    >
      <IconButton
        id="menu-home-btn"
        size="large"
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "grid",
            lg: "grid",
            xl: "grid",
          },
        }}
      >
        <ABCLogo />
      </IconButton>
      <MenuIcon
        handleMenuClick={handleMenuClick}
        icon={{
          name: HOME,
          src: <HomeIcon />,
          srcActive: <HomeIconActive />,
          href: "/",
        }}
      />
      <MenuIcon
        handleMenuClick={handleMenuClick}
        icon={{
          name: isFusion ? POINT : COIN,
          src: isFusion ? (
            <Box
              id="point-icon-png"
              sx={{
                background: `url("${PointIconPng}")`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: "24px",
                width: "24px",
              }}
            />
          ) : (
            <CoinIcon data-testid="coint-icon" />
          ),
          srcActive: isFusion ? (
            <Box
              id="point-icon-active-png"
              sx={{
                background: `url("${PointIconActivePng}")`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: "24px",
                width: "24px",
              }}
            />
          ) : (
            <CoinIconActive />
          ),
          href: "/wallet",
        }}
      />

      <MenuIcon
        icon={{
          name: MARKET,
          src: <MarketPlaceHomeIcon />,
          srcActive: <MarketplaceHomeActiveIcon />,
          href: "/marketplace",
        }}
        handleMenuClick={handleMenuClick}
      />
      <MenuIcon
        icon={{
          name: NFT,
          src: <NFTIcon />,
          srcActive: <NFTIconActive />,
          href: "/wallet/nfts",
        }}
        handleMenuClick={handleMenuClick}
      />
      <SettingBtn activeDrawerMenu={activeDrawerMenu} />
    </Box>
  );
};

export default Menu;
