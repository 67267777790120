import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  getERC721HandlerContract,
  getGalleryContract,
} from "../utils/contractHelper";

export const useNftContract = contract => {
  const { library, account } = useWeb3React();
  return useMemo(
    () =>
      getERC721HandlerContract(
        contract,
        account ? library?.getSigner(account).connectUnchecked() : null,
      ),
    [contract, library, account],
  );
};

export const useGalleryContract = contract => {
  const { library, account } = useWeb3React();
  return useMemo(
    () =>
      getGalleryContract(
        contract,
        account ? library?.getSigner(account).connectUnchecked() : null,
      ),
    [contract, library, account],
  );
};
