import Keycloak from "keycloak-js";
import { GrowthBook } from "@growthbook/growthbook-react";

export const growthbook = new GrowthBook({
  disableDevTools: process.env.REACT_APP_GROWTHBOOK_DEV_TOOL === "false",
});
export const debugFlag = {
  enableDebugTool: process.env.REACT_APP_DEBUG_TOOL === "true",
};
const keycloakEndpoint = `${process.env.REACT_APP_AUTH_BASE_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}`;
const growthbookEndpoint = process.env.REACT_APP_GROWTHBOOK_ENDPOINT;

export const apiEndpoint = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  tos: process.env.REACT_APP_TNC_ENDPOINT,
  deals: `${process.env.REACT_APP_BASE_URL}/wallet-vouchers-service/v1`,
  merchants: `${process.env.REACT_APP_BASE_URL}/wallet-vouchers-service/v1`,
  coins: `${process.env.REACT_APP_BASE_URL}/tokens`,
  vouchers: `${process.env.REACT_APP_BASE_URL}/wallet-vouchers-service/v1`,
  profile: `${process.env.REACT_APP_BASE_URL}/wallet-profiles`,
  partner: process.env.REACT_APP_BASE_URL,
  nfts: `${process.env.REACT_APP_BASE_URL}/wallet-mynfts-service/v1`,
  nftMyCollection: `${process.env.REACT_APP_BASE_URL}/abc-nft-service/v1`,
  rate: `${process.env.REACT_APP_POINT_EXCHANGE_ENDPOINT}/v1`,
  convert: `${process.env.REACT_APP_POINT_EXCHANGE_ENDPOINT}/v1`,
  trueId: `${process.env.REACT_APP_POINT_EXCHANGE_ENDPOINT}/v1`,
  transactions: `${process.env.REACT_APP_COIN_TRANSACTION_ENDPOINT}/v1`,
  transferCoin: `${process.env.REACT_APP_TRANSFER_COIN_ENDPOINT}`,
  authTrueId: process.env.REACT_APP_AUTH_TRUE_ID_URL,
  abcPointExchange: `${process.env.REACT_APP_BASE_URL}/point-exchange/v2`,
  keycloak: keycloakEndpoint,
  keycloakBroker: `${keycloakEndpoint}/broker`,
  keycloakProtocol: `${keycloakEndpoint}/protocol`,
  growthbook: growthbookEndpoint,
  growthbookFeatureFlags: `${growthbookEndpoint}/api/features/${process.env.REACT_APP_GROWTHBOOK_KEY}?project=${process.env.REACT_APP_GROWTHBOOK_PROJ_KEY}`,
  visitNFT: process.env.REACT_APP_VISIT_NFT_ENDPOINT,
};

export const serviceEndpoint = {
  f2c: "f2c",
};

export const S3Endpoint = {
  partnerLogo: `${process.env.REACT_APP_S3_ENDPOINT}/partners/{:partnerId}_{:lang}.png`,
};

export const EARTH_CHANNEL = "earth";
export const FUSION_CHANNEL = "fusion";
const FAQ_LINK_EARTH = process.env.REACT_APP_EARTH_FAQS_URL;
const FAQ_LINK_FUSION = process.env.REACT_APP_FUSION_FAQS_URL;
const EARTH_CUSTOMER_SUPPORT = process.env.REACT_APP_EARTH_CUSTOMER_SUPPORT;
const FUSION_CUSTOMER_SUPPORT = process.env.REACT_APP_FUSION_CUSTOMER_SUPPORT;

const channelsConfig = {
  earth: {
    dev: {
      channel: EARTH_CHANNEL,
      keycloakClient: "myabc.app",
      abcCoinSymbol: "ABC",
      showCoin: true,
      keycloakLogin: true,
      faqLink: FAQ_LINK_EARTH,
      customerSupport: EARTH_CUSTOMER_SUPPORT,
    },
    qa: {
      channel: EARTH_CHANNEL,
      keycloakClient: "myabc.app",
      abcCoinSymbol: "ABC",
      showCoin: true,
      keycloakLogin: true,
      faqLink: FAQ_LINK_EARTH,
      customerSupport: EARTH_CUSTOMER_SUPPORT,
    },
    stg: {
      channel: EARTH_CHANNEL,
      keycloakClient: "myabc.app",
      abcCoinSymbol: "ABC",
      showCoin: true,
      keycloakLogin: true,
      faqLink: FAQ_LINK_EARTH,
      customerSupport: EARTH_CUSTOMER_SUPPORT,
    },
    prod: {
      channel: EARTH_CHANNEL,
      keycloakClient: "myabc.app",
      abcCoinSymbol: "ABC",
      showCoin: true,
      keycloakLogin: true,
      faqLink: FAQ_LINK_EARTH,
      customerSupport: EARTH_CUSTOMER_SUPPORT,
    },
  },
  fusion: {
    dev: {
      channel: FUSION_CHANNEL,
      keycloakClient: "tmn-th.myabc.app",
      abcCoinSymbol: "ABCP",
      showCoin: false,
      keycloakLogin: false,
      faqLink: FAQ_LINK_FUSION,
      customerSupport: FUSION_CUSTOMER_SUPPORT,
    },
    qa: {
      channel: FUSION_CHANNEL,
      keycloakClient: "tmn-th.myabc.app",
      abcCoinSymbol: "ABCP",
      showCoin: false,
      keycloakLogin: false,
      faqLink: FAQ_LINK_FUSION,
      customerSupport: FUSION_CUSTOMER_SUPPORT,
    },
    stg: {
      channel: FUSION_CHANNEL,
      keycloakClient: "tmn-th.myabc.app",
      abcCoinSymbol: "ABCP",
      showCoin: false,
      keycloakLogin: false,
      faqLink: FAQ_LINK_FUSION,
      customerSupport: FUSION_CUSTOMER_SUPPORT,
    },
    prod: {
      channel: FUSION_CHANNEL,
      keycloakClient: "tmn-th.myabc.app",
      abcCoinSymbol: "ABCP",
      showCoin: false,
      keycloakLogin: false,
      faqLink: FAQ_LINK_FUSION,
      customerSupport: FUSION_CUSTOMER_SUPPORT,
    },
  },
};

export const channels = {
  localhost: channelsConfig.earth.dev,
  "myabc-dev.abc-dev.network": channelsConfig.earth.dev,
  "myabc-qa.abc-dev.network": channelsConfig.earth.qa,
  "myabc-stg.abc-dev.network": channelsConfig.earth.stg,
  "myabc.app": channelsConfig.earth.prod,
  "tmn-th-dev.abc-dev.network": channelsConfig.fusion.dev,
  "tmn-th-qa.abc-dev.network": channelsConfig.fusion.qa,
  "tmn-th-stg.abc-dev.network": channelsConfig.fusion.stg,
  "tmn-th.myabc.app": channelsConfig.fusion.prod,
};

export const getChannelConfig = (hostname = window.location.hostname) => {
  return channels[hostname];
};

export const isEarth = getChannelConfig().channel === EARTH_CHANNEL;
export const isFusion = getChannelConfig().channel === FUSION_CHANNEL;

export const termsChannel = isEarth ? "abc-wallet-tnc" : "tmn-th";

export const authConfig = {
  clientId: getChannelConfig().keycloakClient,
  trueClientId: isEarth
    ? process.env.REACT_APP_AUTH_CLIENT_ID_EARTH
    : process.env.REACT_APP_AUTH_CLIENT_ID_FUSION,
};

export const keycloak = new Keycloak({
  url: `${process.env.REACT_APP_AUTH_BASE_URL}`,
  realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
  clientId: getChannelConfig().keycloakClient,
});

export const getFeatureFlags = () => {
  return {
    enableEinsteinSprint17: growthbook.getFeatureValue("einstein-sprint-17"),
    enableNftSprint16: growthbook.getFeatureValue("nft-sprint-16"),
    enableNftMyCollection: growthbook.getFeatureValue("nft-my-collection"),
    enableRelease_1_3_0: growthbook.getFeatureValue("release-1.3.0"),
    enablePartnerDirectUrl: growthbook.getFeatureValue("partner-direct-url"),
    enableTeslaSprint16_08_09_2021:
      growthbook.getFeatureValue("tesla-sprint-16"),
    enableAccountSetting: growthbook.getFeatureValue("account-setting"),
    enableConvertLoyaltyPoint: growthbook.getFeatureValue(
      "convert-loyalty-point",
    ),
    enableF2C: growthbook.getFeatureValue("f2c"),
    enableNftBridge: growthbook.getFeatureValue("nft-bridge"),
    enableP2P: growthbook.getFeatureValue("p2p"),
  };
};

export const genericConfig = {
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
  MIXPANEL_TMN_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN_TMN,
  FAQS_URL_EARTH: FAQ_LINK_EARTH,
  FAQS_URL_FUSION: FAQ_LINK_FUSION,
};
