import { Box, Button, Container, Typography } from "@mui/material";
import PhoneNumberField from "components/PhoneNumberField/PhoneNumberField";
import SplitTopBottomLayout from "components/SplitTopBottomLayout/SplitTopBottomLayout";
import useTranslation from "hooks/useTranslation";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

export const transformMobile = value =>
  value?.split(" ")?.slice(1)?.join(" ").replace(/\s/g, "").startsWith("0")
    ? value?.split(" ")[0] +
      value?.split(" ")?.slice(1)?.join(" ").replace(/\s/g, "").substring(1)
    : value.replace(/\s/g, "");

const EditMobileNumber = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "editMobileNumber",
  });
  const navigate = useNavigate();
  const [mobileNo, setMobileNo] = useState("");
  const [mobileValidate, setIsMobileValidate] = useState({
    isError: false,
    isShow: false,
    message: "",
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (transformMobile(mobileNo).length !== 12) {
      setIsMobileValidate({
        isError: true,
        isShow: true,
        message: t("Invalid mobile number"),
      });
      return;
    } else {
      setIsMobileValidate({
        isError: false,
        isShow: false,
        message: "",
      });
    }
    navigate("/settings/account-settings/send-otp", {
      state: {
        mobileNumber: mobileNo,
      },
    });
  };
  useEffect(() => {
    if (transformMobile(mobileNo).length === 12) {
      setIsMobileValidate(prev => ({
        ...prev,
        isError: false,
        isShow: false,
        message: "",
      }));
    }
  }, [mobileNo]);

  return (
    <SplitTopBottomLayout>
      <Helmet>
        <title>{t("Change Mobile Number")}</title>
      </Helmet>
      <Container>
        <Typography variant="subtitle1" sx={{ marginTop: 5, marginBottom: 1 }}>
          {t("Mobile number")}
        </Typography>
        <Box>
          <PhoneNumberField
            data-testid="phonenumber-field"
            value={mobileNo}
            setValue={setMobileNo}
            mobileValidate={mobileValidate}
          />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            {t("We'll SMS you a 6-digit code to this number for verification")}
          </Typography>
        </Box>
      </Container>

      <Container
        id="btn-container"
        sx={{
          marginTop: 10,
          backgroundColor: "background.paper",
          width: "100%",
          padding: "0 16px 32px 16px",
        }}
      >
        <Button
          data-testid="next-btn"
          id="next-btn"
          variant="contained"
          fullWidth
          sx={{ height: 48, width: "100%", borderRadius: "24px" }}
          onClick={handleSubmit}
        >
          <Typography variant="button" color="background.paper">
            {t("NEXT")}
          </Typography>
        </Button>
      </Container>
    </SplitTopBottomLayout>
  );
};

export default EditMobileNumber;
