export const getPublicChainCollection = abcCollection => {
  return JSON.parse(process.env.REACT_APP_ฺBRIDGE_COLLECTION_PUBLIC)[
    JSON.parse(process.env.REACT_APP_ฺBRIDGE_COLLECTION_ABC).indexOf(
      abcCollection,
    )
  ];
};

export const getResourceIdCollection = abcCollection => {
  return JSON.parse(process.env.REACT_APP_BRIDGE_RESOURCE_ID)[
    JSON.parse(process.env.REACT_APP_ฺBRIDGE_COLLECTION_ABC).indexOf(
      abcCollection,
    )
  ];
};
