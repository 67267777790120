import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  Typography,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import useTranslation from "hooks/useTranslation";
import useNavigationHook from "hooks/useNavigation";
import useNavbarHeight from "hooks/useNavbarHeight";
import SouthIcon from "@mui/icons-material/South";
import { ReactComponent as EthIcon } from "assets/icons/eth_icon.svg";
import { useWeb3React } from "@web3-react/core";
import { trackTapConfirmBridge } from "mixpanel/nft";
import BridgeModal from "./bridgeModal/BridgingModal";

const ReviewCard = styled(Box)`
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  gap: 16px;
  margin: 16px;
`;

const IconBox = styled(Box)`
  height: 40px;
  min-width: 40px;
  border-radius: 100%;
  border: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const DetailDivinder = styled(Divider)`
  width: 90%;
  border-color: #e1e1e1;
  margin: auto;
`;

const SendNftComplete = () => {
  const { token, collection } = useLocation().state;
  const { t } = useTranslation("translation", {
    keyPrefix: "bridgeNft",
  });
  const { active, account } = useWeb3React();
  const navigate = useNavigate();
  const setActiveMenu = useNavigationHook();
  const navbarHeight = useNavbarHeight();
  const [isBridging, setIsBridging] = useState(false);
  const isDesktopSize = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef(null);
  useEffect(() => {
    setFooterHeight(footerRef.current.clientHeight);
  }, []);

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    if (!active) handleOnClickCancel();
  }, [active]);

  const handleOnClickCancel = () =>
    navigate("/wallet/nfts/my-collection/bridge-detail-nft", {
      state: {
        collection,
        token,
      },
    });
  const handleConfirmBridge = () => {
    trackTapConfirmBridge();
    setIsBridging(true);
  };

  return (
    <>
      <Helmet>
        <title>{t("Review")}</title>
      </Helmet>
      <Box maxWidth="552px" m="auto">
        <Box
          sx={{
            overflowY: "auto",
            height: `calc(100vh - ${navbarHeight}px - ${footerHeight}px)`,
            gap: "16px",
          }}
          p="16px"
          display="flex"
          flexDirection="column"
        >
          <ReviewCard>
            <Typography variant="subtitle">{t("You send")}</Typography>
            <Box display="flex" sx={{ gap: "8px" }}>
              <Box
                data-testid="img_nft-image"
                component="img"
                src={token?.metadata?.image}
                height="40px"
                width="40px"
                borderRadius="100%"
              />
              <Box display="flex" flexDirection="column" sx={{ gap: "8px" }}>
                <Typography variant="body1" data-testid="text_nft-name">
                  {token.metadata?.name}
                </Typography>
                <Typography variant="body2" data-testid="text_nft-desc">
                  {token.metadata?.description}
                </Typography>
                <Typography variant="body2" data-testid="text_nft-token-id">
                  {"#" + token.tokenID.toString().padStart(3, "0")}
                </Typography>
                <Typography variant="body2">ABC Chain</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
              <SouthIcon></SouthIcon>
              <DetailDivinder />
            </Box>
            <Typography variant="subtitle">{t("To")}</Typography>
            <Box display="flex" sx={{ gap: "8px" }}>
              <IconBox>
                <EthIcon />
              </IconBox>
              <Box display="flex" flexDirection="column" sx={{ gap: "8px" }}>
                <Typography data-testid="text_chain-name" variant="body2">
                  {t("Ethereum")}
                </Typography>
                <Typography
                  sx={{ wordBreak: "break-word" }}
                  data-testid="text_wallet-address"
                  variant="body1"
                >
                  {active ? account : "-"}
                </Typography>
              </Box>
            </Box>
          </ReviewCard>
        </Box>
        <Paper
          sx={{
            position: isDesktopSize ? "sticky" : "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
          elevation={0}
        >
          <Box
            ref={footerRef}
            p="16px"
            m="auto"
            width="360px"
            display="flex"
            flexDirection="column"
            sx={{ padding: "16px 16px 32px 16px", gap: "16px" }}
          >
            <Button
              data-testid="btn_confirm"
              variant="contained"
              sx={{ height: "48px", borderRadius: "24px" }}
              fullWidth
              display="flex"
              onClick={handleConfirmBridge}
            >
              {t("Confirm")}
            </Button>
            <Button
              data-testid="btn_cancel"
              variant="text"
              fullWidth
              sx={{ height: "48px", borderRadius: "24px" }}
              onClick={handleOnClickCancel}
            >
              <Typography variant="button">{t("Cancel")}</Typography>
            </Button>
          </Box>
        </Paper>
      </Box>
      <BridgeModal
        isLoading={isBridging}
        token={token}
        collection={collection}
      />
    </>
  );
};

export default SendNftComplete;
