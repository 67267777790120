import { Typography, Box, Button, Divider, Avatar } from "@mui/material";
import { Helmet } from "react-helmet-async";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Coins from "../../../../assets/icons/coins.svg";
import { twoDecimalCoin, coinFormatter } from "../../../../utils/coinFormatter";
import RedemptionMethodSkeleton from "../../skeletonLoading/RedemptionMethodSkeleton";
import useTranslation from "../../../../hooks/useTranslation";
import useLoadingHook from "../../../../hooks/useLoadingHook";
import Prompt from "../../../../components/Prompt/Prompt";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  redeemAsync,
  resetRedeem,
  status as RedeemStatus,
} from "../../redux/slices/deals/Redeem";

import {
  fetchAsyncCoins,
  status as CoinsStatus,
} from "../../../wallet/redux/slices/coins/Coins";
import useNavigationHook from "../../../../hooks/useNavigation";
import { getChannelConfig, FUSION_CHANNEL, isFusion } from "../../../../config";

const RedemptionSummary = () => {
  const navigate = useNavigate();
  const setActiveMenu = useNavigationHook();
  const deal = useLocation().state.deal;
  const { id, title, priceAbc, merchant } = deal;
  const dispatch = useDispatch();
  const { coins, status } = useSelector(state => state.coins);
  const redeem = useSelector(state => state.redeem);
  const { t } = useTranslation("translation", {
    keyPrefix: "redemptionSummary",
  });
  const [quantity] = React.useState(1);
  const progressScreen = useLoadingHook();
  const [promptOpen, setPromptOpen] = React.useState(false);
  const [hasBalanceError, setHasBalanceError] = React.useState(false);
  const [promptError, setpromptError] = React.useState({
    title: null,
    subtitle: null,
    buttonText: null,
    onClose: () => {},
  });
  React.useEffect(() => {
    if (status !== CoinsStatus.LOADING) {
      dispatch(fetchAsyncCoins());
    }
    setActiveMenu(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (redeem.status === RedeemStatus.LOADING) {
      progressScreen.setOpen(true);
    } else if (redeem.status === RedeemStatus.ERROR) {
      progressScreen.setOpen(false);
      setPromptOpen(true);
      if (redeem.errorMessage.code === "InsufficientBalance") {
        setHasBalanceError(true);
        setpromptError({
          title: "error_modal_insufficient_balance_title",
          subtitle: "error_modal_insufficient_balance_message",
          buttonText: "error_modal_insufficient_balance_button",
          onClose: () => navigate(-1),
        });
        return;
      }

      if (redeem.errorMessage.code === 80001) {
        setpromptError({
          title: "error_modal_sold_out_title",
          subtitle: "error_modal_sold_out_message",
          buttonText: "error_modal_sold_out_button",
          onClose: () => navigate(`/marketplace`),
        });
        return;
      }

      if (redeem.errorMessage.code === 80006) {
        setpromptError({
          title: "error_modal_personal_limit_daily_title",
          subtitle: "error_modal_personal_limit_daily_message",
          buttonText: "error_modal_personal_limit_daily_button",
          onClose: () => navigate(-1),
        });
        return;
      }

      if (redeem.errorMessage.code === 80005) {
        setpromptError({
          title: "error_modal_personal_limit_monthly_title",
          subtitle: "error_modal_personal_limit_monthly_message",
          buttonText: "error_modal_personal_limit_monthly_button",
          onClose: () => navigate(-1),
        });
        return;
      }

      if (redeem.errorMessage.code === 80009) {
        setpromptError({
          title: "error_modal_deal_quota_daily_title",
          subtitle: "error_modal_deal_quota_daily_message",
          buttonText: "error_modal_deal_quota_daily_button",
          onClose: () => navigate(-1),
        });
        return;
      }

      if (redeem.errorMessage.code === 80008) {
        setpromptError({
          title: "error_modal_deal_quota_monthly_title",
          subtitle: "error_modal_deal_quota_monthly_message",
          buttonText: "error_modal_deal_quota_monthly_button",
          onClose: () => navigate(-1),
        });
        return;
      }
      // this will handle 80000 and other
      setpromptError({
        title: "error_modal_default_title",
        subtitle: "error_modal_default_message",
        buttonText: "error_modal_default_button",
        onClose: () => navigate(-1),
      });
      return;
    } else if (redeem.status === RedeemStatus.LOADED) {
      progressScreen.setOpen(false);
      navigate(`/marketplace/deal/${id}/success`, {
        state: {
          redeem,
          coins,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redeem.status]);

  React.useEffect(() => {
    return () => {
      dispatch(resetRedeem());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RedemptionMethod = ({ hasError }) => {
    return (
      <Box
        data-testid="redemption-method"
        sx={{
          backgroundColor: "background.paper",
          border: 1,
          borderColor: "grey.accent3",
          borderRadius: "16px",
          mt: "8px",
          mb: "170px",
        }}
      >
        <Box sx={{ m: "16px 16px 16px 16px" }}>
          <Typography variant="subtitle" color="grey.accent1">
            {t("Redemption Method")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              mt: "16px",
            }}
          >
            <Box
              alt="Coins"
              component="img"
              src={Coins}
              sx={{
                objectFit: "cover",
                height: "40px",
                width: "40px",
                mr: "8px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ mb: "8px" }}
                variant="body2"
                color="grey.accent1"
              >
                {isFusion ? t("ABC Point") : t("ABC coin")}
              </Typography>

              {hasError ? (
                <Typography variant="subtitle" color="grey.accent1">
                  {t("Unable to retrieve balance")}
                </Typography>
              ) : (
                <Typography variant="subtitle" color="grey.accent1">
                  {t("Balance") + ": " + coinFormatter(coins.balance) + " ABC"}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const handleClick = () => {
    dispatch(redeemAsync({ id, priceAbc }));
  };

  return (
    <>
      <Helmet>
        <title>{t("Redemption")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "0 16px",
          pt: "32px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            border: 1,
            borderColor: "grey.accent3",
            borderRadius: "16px",
            mb: "24px",
          }}
        >
          <Box sx={{ m: "16px 16px" }}>
            <Typography variant="subtitle" color="grey.accent1">
              {t("Redemption Summary")}
            </Typography>
            <Box sx={{ mt: "16px", mb: "20px", display: "flex" }}>
              <Avatar
                sx={{
                  height: "40px",
                  width: "40px",
                  mr: "8px",
                }}
                src={merchant.logo}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{ mb: "8px" }}
                  variant="subtitle"
                  color="grey.accent1"
                >
                  {merchant.name}
                </Typography>
                <Typography variant="body2" color="grey.accent1">
                  {title}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ m: "16px 0" }}>
              <Box
                sx={{
                  mb: "16px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" color="grey.accent2">
                  {t("Price per Voucher")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    alt="Coins"
                    component="img"
                    src={Coins}
                    sx={{
                      objectFit: "cover",
                      height: "24px",
                      width: "24px",
                      mr: "8px",
                    }}
                  />
                  <Typography variant="body2" color="grey.accent1">
                    {twoDecimalCoin(priceAbc) + " ABC"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2" color="grey.accent2">
                  {t("Quantity")}
                </Typography>
                <Typography variant="body2" color="grey.accent1">
                  {quantity}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {status === CoinsStatus.LOADING ? (
          <RedemptionMethodSkeleton id="redemption-loading" />
        ) : status === CoinsStatus.ERROR ? (
          <RedemptionMethod id="redemption-error" hasError={true} />
        ) : coins && status === CoinsStatus.LOADED ? (
          <RedemptionMethod id="redemption-method" hasError={false} />
        ) : null}
      </Box>

      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "background.paper",
          width: "100%",
          padding: "0 16px 32px 16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: "0 16px 16px",
          }}
        >
          <Typography variant="subtitle" color="grey.accent1">
            {t("Grand Total Amount")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              alt="Coins"
              component="img"
              src={Coins}
              sx={{
                objectFit: "cover",
                height: "24px",
                width: "24px",
                mr: "16px",
              }}
            />
            <Typography variant="subtitle" color="grey.accent1">
              {twoDecimalCoin(priceAbc * quantity) + " ABC"}
            </Typography>
          </Box>
        </Box>
        <Button
          id="confirm"
          data-testid="confirm"
          variant="contained"
          fullWidth
          sx={{ height: 48, width: "100%", borderRadius: "24px" }}
          disabled={
            coins && status === CoinsStatus.LOADED && !hasBalanceError
              ? false
              : true
          }
          onClick={handleClick}
        >
          <Typography variant="button" color="background.paper">
            {t("Confirm")}
          </Typography>
        </Button>
      </Box>

      <Prompt
        isVisible={promptOpen}
        icon={"error"}
        onClose={promptError.onClose}
        title={promptError.title}
        subTitle={promptError.subtitle}
        buttonText={promptError.buttonText}
        keyPrefix="redemption_summary_error"
      />
    </>
  );
};
export default RedemptionSummary;
