import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import useKeycloakSwitch from "channel/useKeycloakSwitch";
import {
  getChannelConfig,
  EARTH_CHANNEL,
  FUSION_CHANNEL,
} from "../../../../config";
import Prompt from "../../../../components/Prompt/Prompt";
import {
  channelName,
  trackChannelUsed,
  trackCompletedLinkingLoyaltyPartner,
  trackErrorLinkingLoyaltyPartner,
} from "../../../../mixpanel";
import { Status } from "../../../../redux/constant";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import { getAccessToken } from "utils/token";
import {
  fetchAsyncConvertPartnerLink,
  resetConvertPartnerLink,
} from "features/wallet/redux/slices/convertPartnerCoins/convertPartnerLink";

const VerifyLink = () => {
  const { keycloak } = useKeycloakSwitch();
  const { partnerId: paramPartnerId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status: convertPartnerLinkStatus, error: convertPartnerLinkError } =
    useSelector(state => state.convertPartnerLink);

  const [promptOpen, setPromptOpen] = useState(false);

  const queryParam = useRef(queryString.parse(location.search));

  const partnerId = useMemo(() => {
    if (paramPartnerId === "krungsri") {
      return queryParam.current?.state || paramPartnerId;
    }
    return paramPartnerId;
  }, [paramPartnerId]);

  useEffect(() => {
    return () => {
      dispatch(resetConvertPartnerLink());
    };
  }, [dispatch]);

  useEffect(() => {
    trackChannelUsed(channelName);
  }, []);

  const mutiChannelFunc = useMemo(
    () => ({
      [EARTH_CHANNEL]: {
        token: keycloak.token,
        isAuthenticated: keycloak.authenticated,
        login: () => {
          const lang = localStorage.getItem("lang") || "th";
          keycloak.login({ locale: lang });
        },
      },
      [FUSION_CHANNEL]: {
        token: getAccessToken(),
        isAuthenticated: !!getAccessToken(),
        login: () => {
          window.location.replace("ascendmoney://closewebview");
        },
      },
    }),
    [keycloak],
  );

  useEffect(() => {
    if (!mutiChannelFunc[getChannelConfig().channel].isAuthenticated) {
      mutiChannelFunc[getChannelConfig().channel].login();
    }
  }, [mutiChannelFunc]);

  useEffect(() => {
    if (!mutiChannelFunc[getChannelConfig().channel].isAuthenticated) return;
    if (
      !queryParam.current?.code &&
      !localStorage.getItem(`${partnerId}Code`)
    ) {
      setPromptOpen(true);
      return;
    }
    if (queryParam.current?.code) {
      localStorage.setItem(`${partnerId}Code`, queryParam.current?.code);
    }
    dispatch(
      fetchAsyncConvertPartnerLink({
        partnerId,
        code:
          queryParam.current?.code || localStorage.getItem(`${partnerId}Code`),
      }),
    );
  }, [partnerId, mutiChannelFunc, dispatch]);

  const handleLinked = useCallback(() => {
    navigate(`/partner-convert/${partnerId}`, {
      state: {
        partnerId,
      },
    });
  }, [navigate, partnerId]);

  useEffect(() => {
    if (convertPartnerLinkStatus === Status.LOADED) {
      trackCompletedLinkingLoyaltyPartner(partnerId);
      localStorage.removeItem(`${partnerId}Code`);
      handleLinked();
    } else if (convertPartnerLinkStatus === Status.ERROR) {
      if (convertPartnerLinkError?.statusCode === 400) {
        localStorage.removeItem(`${partnerId}Code`);
        trackErrorLinkingLoyaltyPartner({
          partnerName: partnerId,
          errorCode: convertPartnerLinkError?.statusCode,
          errorMessage: convertPartnerLinkError?.errorMessage,
        });
      } else {
        trackErrorLinkingLoyaltyPartner({
          partnerName: partnerId,
          errorCode: "not_found",
          errorMessage: "Linked account unavailable",
        });
      }
      setPromptOpen(true);
    }
  }, [
    convertPartnerLinkStatus,
    convertPartnerLinkError,
    partnerId,
    handleLinked,
  ]);

  const handleClosePrompt = () => {
    navigate("/wallet");
  };

  return (
    <>
      <Helmet>
        <title>ABC</title>
      </Helmet>
      <Prompt
        isVisible={promptOpen}
        icon={"error"}
        onClose={handleClosePrompt}
        title={"Linking account unsuccessful"}
        subTitle={"Please try again"}
        buttonText={"OK"}
        keyPrefix={"PartnerConvert"}
      />
    </>
  );
};

export default VerifyLink;
