import {
  Box,
  Modal,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useWindowDimensions from "hooks/useWindowDimensions";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { getCurrentLanguage } from "utils/getLanguage";

const KrungsriTncModal = ({ isVisible, onClose }) => {
  const { height, width } = useWindowDimensions();
  const lang = getCurrentLanguage();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"));

  const renderThaiTnc = () => {
    return (
      <>
        <Typography variant="h6" sx={{ mb: "12px" }}>
          เงื่อนไขการแลกคะแนนสะสมเป็น ABC Coin
        </Typography>
        <span>
          สิทธิพิเศษสำหรับสมาชิกบัตรเครดิต กรุงศรี
          ทุกประเภทบัตรเครดิตกรุงศรีเฟิร์สช้อยส์ วีซ่า และบัตรเครดิต
          กรุงศรีเฟิร์สช้อยส์ แพลทินั่ม เท่านั้น (บัตรที่ไม่ร่วมรายการได้แก่
          บัตรเครดิต กรุงศรี คอร์ปอเรท การ์ด, บัตรเครดิต ทีซีซี พริวิเลจ การ์ด
          และ บัตรเครดิต กรุงศรี โฮมโปร คอร์ปอเรท การ์ด)
        </span>
        <ul>
          <li>
            <span>
              การแลกคะแนนสะสมเป็น ABC Coin สามารถแลกได้ขั้นต่ำ 1,000 คะแนน
              (เพิ่มขึ้นทุก 1,000 คะแนน) จำกัดการแลกคะแนนสะสม สูงสุด 500,000
              คะแนน/บัตรหลัก/วัน โดยมีอัตรการแลกเปลี่ยนเป็น &nbsp;1,000 คะแนน
              ต่อ 100 ABC coin
            </span>
          </li>
          <li>
            <span>
              ABC Coin ที่แลกจะถูกโอนเข้าอัตโนมัติไปยัง ABC Wallet
              &nbsp;หลังจากทำการแลกคะแนนสะสมเป็น ABC Coin
            </span>
          </li>
          <li>
            <span>
              คะแนนจะถูกหักออกจากบัญชีบัตรฯ เมื่อมีการแลกโดยอัตโนมัติ
              ตามจำนวนคะแนนที่สมาชิกบัตรระบุ และไม่สามารถขอยกเลิกได้ทุกกรณี
            </span>
          </li>
          <li>
            <span>
              สงวนสิทธิ์ในการรับสิทธิ์แลกคะแนนเฉพาะบัตรหลักที่มีคะแนนเพียงพอต่อการแลกเท่านั้น
            </span>
          </li>
          <li>
            <span>ไม่สามารถ โอน / เปลี่ยน / แลก / ทอน เป็นเงินสดได้</span>
          </li>
          <li>
            <span>เงื่อนไขการแลก</span>
            <span>&nbsp;</span>
            <span>ABC Coin เป็นสินค้าหรือบริการ </span>
            <span>เป็นไปตามที่บริษัท แอสเซนด์ บิท กำหนด</span>
          </li>
          <li>
            <span>
              กรุณาตรวจสอบข้อกำหนดและเงื่อนไขที่เกี่ยวข้องทั้งหมด
              ก่อนทำการโอนคะแนนสะสมและใช้ ABC Coin
            </span>
          </li>
          <li>
            <span>ศึกษาข้อมูลเกี่ยวกับ ABC Coin ได้ที่ </span>
            <span>
              <a href="https://abccoin.digital/">https://abccoin.digital/</a>
            </span>
            <span>
              &nbsp;หรือติดต่อทางอีเมล &nbsp;wallet.support@ascendbit.net
            </span>
          </li>
          <li>
            <span>
              สอบถามข้อมูลเกียวกับบัตรเครดิต กรุณาติดต่อ บัตรเครดิต กรุงศรี
              ทุกประเภท โทร. 02-646-3555 บัตรเครดิตกรุงศรี เฟิร์สช้อยส์ วีซ่า
              และบัตรเครดิตกรุงศรี เฟิร์สช้อยส์ วีซ่า แพลทินั่ม โทร. 02-345-678
            </span>
          </li>
          <li>
            <span>
              เงื่อนไขเป็นไปตามที่บริษัทฯกำหนด ให้บริการสินเชื่อโดย บริษัท
              บัตรกรุงศรีอยุธยา จำกัด และบริษัท อยุธยาแคปปิตอล เซอร์วิสเซส จำกัด
            </span>
          </li>
        </ul>
      </>
    );
  };

  const renderEngTnc = () => {
    return (
      <>
        <Typography variant="h6" sx={{ mb: "12px" }}>
          Terms and Conditions for the exchange from the points to the ABC
          Coins.
        </Typography>
        <span>
          Exclusive for Krungsri credit card, Krungsri FirstChoice Visa, and
          Krungsri FirstChoice Platinum Credit Card cardholder only (Credit card
          not eligible for point transfer program include Krungsri corporate
          credit cards, TCC Privilege Card and Krungsri HomePro Corporate Cards)
        </span>
        <ul>
          <li>
            <span>
              Minimum point redemption at 1,000 points and use every 1,000
              points transfer to ABC coin 100 coins. Limit point redemption at
              500,000 points/credit card account/day.
            </span>
          </li>
          <li>
            <span>Points will be transferred to ABC coins automatically.</span>
          </li>
          <li>
            <span>
              Credit Card Point will be deducted from the credit card account as
              point transfer at the date of proceed. Completed transactions
              cannot be canceled with no exception.
            </span>
          </li>
          <li>
            <span>
              Eligibility for main card account that has sufficient point for
              point redemption
            </span>
          </li>
          <li>
            <span>Cannot be refunded, exchanged, or redeemed for cash</span>
          </li>
          <li>
            <span>
              ABC coin terms and conditions are specified by Ascend Bit Company.
            </span>
          </li>
          <li>
            <span>More information for ABC Coin at </span>
            <span>
              <a href="https://abccoin.digital/">https://abccoin.digital/</a>
            </span>
            <span>
              &nbsp;or please send an email at wallet.support@ascendbit.net
            </span>
          </li>
          <li>
            <span>
              Credit Card information, please contact 02-646-3555 for Krungsri
              Credit Card, and 02-345-6789 for Krungsri FirstChoice Visa and
              Krungsri FirstChoice Visa Platinum Credit Card.
            </span>
          </li>
          <li>
            <span>
              Terms and Conditions apply, Credit Card service is provided by
              Krungsri Ayudhya Card Company Limited and Ayudhya Capital Services
              Company Limited.
            </span>
          </li>
        </ul>
      </>
    );
  };

  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
      data-testid="krungsri-tnc-modal"
    >
      <Box
        sx={[
          {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            m: "16px",
            pb: "16px",
            borderRadius: "16px",
            bgcolor: "background.paper",
            maxWidth: isDesktop ? "60%" : `calc(${width}px - 32px)`,
            minHeight: "144px",
            maxHeight: `calc(${height}px - 64px)`,
          },
          () => ({
            "&:focus-visible": {
              outline: "none",
            },
          }),
        ]}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            pt: "24px",
            pr: "24px",
          }}
        >
          <IconButton data-testid="btn-close-top-right" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{ overflowY: "auto", px: "24px", pt: "6px" }}
          data-testid="tnc-content"
        >
          {lang === "th" ? renderThaiTnc() : renderEngTnc()}
        </Box>
      </Box>
    </Modal>
  );
};

export default KrungsriTncModal;
