import React from "react";
import { Box, Divider, Typography, Button, Avatar } from "@mui/material";
import parse from "html-react-parser";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import { Helmet } from "react-helmet-async";
import Coins from "../../../../assets/icons/coins.svg";
import useNavigationHook from "../../../../hooks/useNavigation";
import { twoDecimalCoin } from "../../../../utils/coinFormatter";
import { formatDateTime } from "../../../../utils/dateTimeFormatter";
import { trackViewVoucher } from "../../../../mixpanel";

const DetailedDeals = () => {
  const deal = useLocation().state.deal;
  const {
    id,
    title,
    tagline,
    desc,
    bgPicture,
    tnc,
    priceAbc,
    startDateTime,
    endDateTime,
    merchant,
    category,
  } = deal;
  const { t } = useTranslation("translation", { keyPrefix: "detailedDeals" });
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setActiveMenu(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    trackViewVoucher(merchant?.name, category, id, title, priceAbc);
  }, [merchant, category, id, title, priceAbc]);

  return (
    <>
      <Helmet>
        <title>{t("Voucher")}</title>
      </Helmet>
      <Box
        sx={{
          display: "grid",
          overflow: "auto",
        }}
      >
        <Box sx={{ p: "32px 16px 60px 16px" }}>
          <Box
            component="img"
            sx={{
              height: { xs: "135px", sm: "256px" },
              width: "100%",
              objectFit: "cover",
              borderRadius: "16px",
              mb: "24px",
            }}
            src={bgPicture}
          />
          <Box
            sx={{
              width: "100%",
              background: "background.paper",
              border: 1,
              borderColor: "grey.accent3",
              borderRadius: "16px",
              p: "16px",
            }}
          >
            <Box sx={{ display: "flex", mb: "8px" }}>
              <Box sx={{ mr: "8px" }}>
                {merchant.logo ? (
                  <Avatar
                    sx={{
                      height: "24px",
                      width: "24px",
                    }}
                    src={merchant.logo}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: "24px",
                      width: "24px",
                    }}
                  >
                    <Typography sx={{ fontSize: "11px" }}>{"ABC"}</Typography>
                  </Avatar>
                )}
              </Box>
              <Typography
                variant="body2"
                sx={{
                  color: "grey.accent1",
                }}
              >
                {merchant.name}
              </Typography>
            </Box>
            <Box sx={{ mb: "16px" }}>
              <Typography variant="h6">{title}</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: "16px 0",
              }}
            >
              <Box sx={{ mr: "4px" }}>
                <Box
                  alt="Coins"
                  component="img"
                  src={Coins}
                  sx={{
                    height: "24px",
                    width: "24px",
                    display: "flex",
                    alignItems: "center",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Typography variant="body1" color="grey.accent1">
                {`${twoDecimalCoin(priceAbc)} ${t("Price")}`}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ pt: "16px" }}>
              <Typography variant="body2" color="grey.accent1">
                {`${t("Redemption")}: ${formatDateTime(
                  startDateTime,
                  "D MMM YYYY",
                )} ${t("To")} ${formatDateTime(endDateTime, "D MMM YYYY")}`}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              background: "background.paper",
              border: 1,
              borderColor: "grey.accent3",
              borderRadius: "16px",
              my: 2,
              p: 2,
            }}
          >
            <Typography variant="subtitle">{tagline}</Typography>
            <Typography variant="body1" sx={{ pt: 1 }}>
              {desc}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              background: "background.paper",
              border: 1,
              borderColor: "grey.accent3",
              borderRadius: "16px",
              mt: 2,
              mb: 4,
              p: 2,
            }}
          >
            <Typography
              variant="subtitle"
              sx={{
                mb: 1,
              }}
            >
              {t("Terms")}
            </Typography>
            <Typography
              sx={{
                pt: 1,
                color: "grey.accent2",
                wordBreak: "break-all",
              }}
            >
              {parse(tnc.replace(/(\r\n|\r|\n)/g, "<br>"))}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "background.paper",
          width: "100%",
          padding: "0 16px 32px 16px",
        }}
      >
        <Button
          id="redeem-btn"
          variant="contained"
          fullWidth
          sx={{ height: 48, borderRadius: "24px" }}
          onClick={() => {
            navigate(`/marketplace/deal/${id}/summary`, {
              state: {
                deal,
              },
            });
          }}
        >
          <Typography variant="button">{t("Redeem")}</Typography>
        </Button>
      </Box>
    </>
  );
};

export default DetailedDeals;
