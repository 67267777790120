import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Snackbar, SnackbarContent, Slide } from "@mui/material";
import useNavigationHook from "../../../../hooks/useNavigation";
import PartnerList from "../../components/partnerConvertIn/partnerList/PartnerList";
import PartnerYourReward from "../../components/partnerConvertIn/partnerYourReward/PartnerYourReward";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  fetchAsyncPartnerList,
  resetPartnerList,
} from "features/wallet/redux/slices/partnerList/partnerList";
import {
  fetchAsyncLinkedPartner,
  resetLinkedPartner,
} from "features/wallet/redux/slices/linkedPartner/linkedPartner";
import VotePartner from "../../components/partnerConvertIn/votePartner/VotePartner";
import { history } from "../../../../history";
import { Status } from "redux/constant";
import useTranslation from "hooks/useTranslation";
import usePartnerBalance from "features/wallet/hooks/usePartnerBalance";

const PartnerConvertIn = () => {
  const setActiveMenu = useNavigationHook();
  const { t } = useTranslation("translation", {
    keyPrefix: "PartnerConvertIn",
  });
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { status: partnerListStatus } = useSelector(state => state.partnerList);
  const { linkedPartner } = useSelector(state => state.linkedPartner);
  const partnersBalance = usePartnerBalance();

  useEffect(() => {
    dispatch(fetchAsyncPartnerList());
    dispatch(fetchAsyncLinkedPartner());
  }, [dispatch]);

  useEffect(() => {
    const unlistenHistory = history.listen(({ location }) => {
      if (location.pathname !== "/wallet/partner-all-your-reward") {
        dispatch(resetPartnerList());
        dispatch(resetLinkedPartner());
      }
    });

    return () => {
      unlistenHistory();
    };
  }, [dispatch]);

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  const haveLinkedPartner = useMemo(
    () =>
      linkedPartner?.find(
        linkedItem => linkedItem.connected && !linkedItem.social,
      ),
    [linkedPartner],
  );

  const isShowYourReward = useMemo(() => {
    return partnerListStatus === Status.LOADED && haveLinkedPartner;
  }, [partnerListStatus, haveLinkedPartner]);

  return (
    <>
      <Helmet>
        <title>{"ConvertIn"}</title>
      </Helmet>
      <Box sx={{ mt: "32px", px: "16px" }}>
        {isShowYourReward && (
          <Box sx={{ mb: "32px" }}>
            <PartnerYourReward partnersBalance={partnersBalance} />
          </Box>
        )}
        <Box sx={{ mb: "32px" }}>
          <PartnerList />
        </Box>
        <Box>
          <VotePartner setOpenSnackbar={setOpenSnackbar} />
        </Box>
      </Box>
      <Snackbar
        id="vote-partner-snackbar"
        data-testid="vote-partner-snackbar"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        TransitionComponent={Slide}
        key={Slide.name}
        autoHideDuration={3000}
      >
        <SnackbarContent
          message={t(
            "Thank you! We will do our best to add your favorite partner.",
          )}
          sx={{ bgcolor: "toast.main", borderRadius: "16px" }}
        />
      </Snackbar>
    </>
  );
};

export default PartnerConvertIn;
