import React from "react";
import { Box, Button, Typography, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import coinsIcon from "../../../../assets/icons/coins.svg";
import GenericErrorImg from "../../../../assets/images/GenericErrorImg.svg";
import useDealsHook from "../../../../hooks/useDealsHook";
import { twoDecimalCoin } from "../../../../utils/coinFormatter";

const CardComponent = ({ deal }) => {
  const { id, title, thumbnail, priceAbc, merchant } = deal;
  const navigate = useNavigate();
  const dealsRef = useDealsHook();

  return (
    <Button
      id="dealsCard"
      data-testid={`btn_deal-card-${id}`}
      sx={{
        maxWidth: '185px',
        minWidth: '185px',
        borderRadius: "16px",
        border: 1,
        borderColor: "grey.accent3",
        bgcolor: "background.paper",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      onClick={() => {
        dealsRef.cardSelected.current = true;
        navigate(`/marketplace/deal/${id}`, {
          state: {
            deal,
          },
        });
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            mr: "4px",
            height: "100%",
          }}
        >
          {merchant.logo ? (
            <Avatar
              data-testid={`img_deal-merchant-logo-${id}`}
              sx={{
                height: "24px",
                width: "24px",
              }}
              src={merchant.logo}
            />
          ) : (
            <Avatar
              data-testid={`img_abc-icon-${id}`}
              sx={{
                height: "24px",
                width: "24px",
              }}
            >
              <Typography sx={{ fontSize: "11px" }}>{"ABC"}</Typography>
            </Avatar>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Typography data-testid={`text_deal-merchant-name-${id}`} variant="body2" color="textPrimary">
            {merchant.name}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flex: 1,
          mt: "4px",
          mb: "4px",
        }}
      >
        <Box
          data-testid={`img_deal-${id}`}
          alt="Cover Image"
          component="img"
          src={thumbnail}
          onError={(ev) => (ev.target.src = GenericErrorImg)}
          sx={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            aspectRatio: "1/1",
          }}
          id="coverImage"
        />
      </Box>

      <Box
        sx={{
          mb: "12px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          data-testid={`text_deal-title-${id}`}
          variant="body2"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            textAlign: "left",
            WebkitLineClamp: 2,
            minHeight: "40px",
          }}
          color="grey.accent2"
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          height: "24px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          alt="Coins"
          component="img"
          src={coinsIcon}
          sx={{
            height: "24px",
            width: "24px",
            mr: "4px",
          }}
        />
        <Typography data-testid={`text_deal-point-${id}`} variant="grey.accent1" color="textPrimary">
          {twoDecimalCoin(priceAbc)}
        </Typography>
      </Box>
    </Button>
  );
};

export default CardComponent;
