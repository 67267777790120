import React, { useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  trackVisitHomePage,
  trackHomeQuickAction,
  QUICK_ACTION_TYPE,
} from "../../mixpanel";
import { useDispatch, useSelector } from "../../redux/store/configureStore";
import {
  fetchAsyncCoins,
  status as CoinsStatus,
} from "../../features/wallet/redux/slices/coins/Coins";
import useAuth from "../../hooks/useAuth";
import useNavigationHook from "../../hooks/useNavigation";
import BalanceCard from "./balanceCard/BalanceCard";
import ProfileSection from "./profileSection/ProfileSection";

import useTranslation from "../../hooks/useTranslation";
import {
  setVisitedConsentPage,
  fetchAsyncConsent,
  status as ConsentStatus,
  resetConsent,
} from "../../redux/slices/consent/Consent";
import { ReactComponent as RedeemIcon } from "../../assets/icons/home/quick-action-redeem.svg";
import { ReactComponent as ConvertIcon } from "../../assets/icons/home/quick-action-convert.svg";
import { ReactComponent as VoucherIcon } from "../../assets/icons/home/quick-action-voucher.svg";

import MarketPlaceImageTH from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V4_Final-01.jpg";
import CoinWebSiteTH from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V4_Final-02.jpg";
import ABCfaqTH from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V4_Final-03.jpg";
import MyWalletNFTTH from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V4_Final-04.jpg";

import MarketPlaceImageEN from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V4_Final-06.jpg";
import CoinWebSiteEN from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V4_Final-07.jpg";
import ABCfaqEN from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V4_Final-08.jpg";
import MyWalletNFTEN from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V4_Final-09.jpg";
import FusionPromotionImageTH from "../../assets/images/Home/redeem-th-20220930-022720.png";
import FusionConvertTH from "../../assets/images/Home/convert-th.png";
import FusionABCfaqTH from "../../assets/images/Home/faq-th-20220930-022720.png";
import FusionMyWalletNFTTH from "../../assets/images/Home/nft-th-20220930-022720.png";

import FusionPromotionImageEN from "../../assets/images/Home/redeem-en-20220930-022720.png";
import FusionConvertEN from "../../assets/images/Home/convert-en.png";
import FusionABCfaqEN from "../../assets/images/Home/faq-en-20220930-022720.png";
import FusionMyWalletNFTEN from "../../assets/images/Home/nft-en-20220930-022720.png";

import { getFeatureFlags, isFusion, getChannelConfig } from "../../config";

const { faqLink } = getChannelConfig();

const Homepage = () => {
  const { enableRelease_1_3_0 } = getFeatureFlags();
  
  const earthBannerList = [
    {
      id: "banner-marketplace",
      src: {
        th: MarketPlaceImageTH,
        en: MarketPlaceImageEN,
      },
      externalLink: "",
      internalLink: "/marketplace",
    },
    {
      id: "banner-coinwebsite",
      src: {
        th: CoinWebSiteTH,
        en: CoinWebSiteEN,
      },
      externalLink: "https://www.abccoin.digital/campaign",
      internalLink: "",
    },
    {
      id: "banner-abc-faq",
      src: {
        th: ABCfaqTH,
        en: ABCfaqEN,
      },
      externalLink: faqLink,
      internalLink: "",
    },
    {
      id: "banner-mywallet-nft",
      src: {
        th: MyWalletNFTTH,
        en: MyWalletNFTEN,
      },
      externalLink: "",
      internalLink: "/wallet/nfts",
    },
  ];

  const fusionBannerList = [
    {
      id: "banner-promotion",
      src: {
        th: FusionPromotionImageTH,
        en: FusionPromotionImageEN,
      },
      externalLink: "",
      internalLink: "/marketplace",
    },
    {
      id: "banner-convert",
      src: {
        th: FusionConvertTH,
        en: FusionConvertEN,
      },
      externalLink: "",
      internalLink: "/wallet/partner-convert-in",
    },
    {
      id: "banner-abc-faq",
      src: {
        th: FusionABCfaqTH,
        en: FusionABCfaqEN,
      },
      externalLink: faqLink,
      internalLink: "",
    },
    {
      id: "banner-mywallet-nft",
      src: {
        th: FusionMyWalletNFTTH,
        en: FusionMyWalletNFTEN,
      },
      externalLink: "",
      internalLink: "/wallet/nfts",
    },
  ];

  const bannerList = isFusion ? fusionBannerList : earthBannerList;
  const { t } = useTranslation("translation", { keyPrefix: "homeScreen" });
  const lang = localStorage.getItem("lang");
  const { user } = useAuth();
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const { coins, status } = useSelector(state => state.coins);
  const {
    visitedConsentPage,
    consents,
    status: consentStatus,
  } = useSelector(state => state.consent);

  const dispatch = useDispatch();

  const handleBannerClick = ({ externalLink, internalLink }) => {
    if (externalLink) {
      window.open(externalLink, "_blank", "width=400,height=600");
    } else {
      navigate(internalLink);
    }
  };

  useEffect(() => {
    if (visitedConsentPage) {
      setActiveMenu(true);
    } else {
      setActiveMenu(false);
    }
  }, [setActiveMenu, visitedConsentPage]);

  useEffect(() => {
    if (visitedConsentPage) {
      dispatch(resetConsent());
      return;
    }
    if (consentStatus === ConsentStatus.INITIAL) {
      dispatch(fetchAsyncConsent("open-app"));
    } else if (consentStatus === ConsentStatus.LOADED) {
      dispatch(setVisitedConsentPage());
      if (consents?.length) {
        navigate("/consent", { replace: true });
      }
    } else if (consentStatus === ConsentStatus.ERROR) {
      dispatch(setVisitedConsentPage());
    }
  }, [consents, consentStatus, visitedConsentPage, dispatch, navigate]);

  useEffect(() => {
    if (visitedConsentPage) {
      if (status === CoinsStatus.INITIAL) {
        dispatch(fetchAsyncCoins());
      }
    }
  }, [dispatch, status, visitedConsentPage]);

  useEffect(() => {
    if (coins) {
      trackVisitHomePage(coins);
    }
  }, [coins]);

  const handleQuickActionClick = (pathname, search, quickActionType) => {
    trackHomeQuickAction(quickActionType);
    navigate({
      pathname,
      search,
    });
  };

  if (!visitedConsentPage) {
    return <div></div>;
  }

  return (
    <>
      <Helmet>
        <title>{t("Home")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ProfileSection user={user} />
        <BalanceCard
          isLoading={status === CoinsStatus.LOADING}
          isError={status === CoinsStatus.ERROR}
          balance={coins?.balance}
        />
        <Typography variant="h6" sx={{ padding: "24px 16px 0 16px" }}>
          {t("Our services")}
        </Typography>
        <Box
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 16px",
          }}
        >
          <IconButton
            data-testid="btn-quick-action-marketplace"
            sx={{ display: "flex", flexDirection: "column", minWidth: "100px" }}
            onClick={() =>
              handleQuickActionClick(
                "/marketplace",
                "",
                QUICK_ACTION_TYPE["Buy Voucher"],
              )
            }
          >
            <RedeemIcon />
            <Typography
              variant="subtitle1"
              sx={{
                color: "#333333",
                marginTop: "8px",
              }}
            >
              {t("Redeem")}
            </Typography>
          </IconButton>
          <IconButton
            data-testid="btn-quick-action-p2c"
            sx={{ display: "flex", flexDirection: "column", minWidth: "100px" }}
            onClick={() => {
              if (enableRelease_1_3_0) {
                handleQuickActionClick(
                  "/wallet/partner-convert-in",
                  "",
                  QUICK_ACTION_TYPE.P2C,
                );
              } else {
                handleQuickActionClick(
                  "/wallet",
                  "?openBinding=true",
                  QUICK_ACTION_TYPE.P2C,
                );
              }
            }}
          >
            <ConvertIcon />
            <Typography
              variant="subtitle1"
              sx={{
                color: "#333333",
                marginTop: "8px",
              }}
            >
              {t("Convert")}
            </Typography>
          </IconButton>
          <IconButton
            data-testid="btn-quick-action-nft"
            sx={{ display: "flex", flexDirection: "column", minWidth: "100px" }}
            onClick={() =>
              handleQuickActionClick(
                "/marketplace/my-vouchers",
                "",
                QUICK_ACTION_TYPE["My Voucher"],
              )
            }
          >
            <VoucherIcon />
            <Typography
              variant="subtitle1"
              sx={{
                color: "#333333",
                marginTop: "8px",
              }}
            >
              {t("My voucher")}
            </Typography>
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            backgroundColor: "grey.accent4",
            flexDirection: "column",
            mt: "25px",
            pt: "39px",
          }}
        >
          <Typography variant="h6" color="grey.accent1" sx={{ px: "16px" }}>
            {t("Learn More")}
          </Typography>
          <Box
            sx={{
              padding: "16px 16px 8px 16px",
              display: "flex",
              gap: "16px",
              width: "100%",
              overflow: "auto",
              whiteSpace: "nowrap",
            }}
          >
            {bannerList.map(({ id, src, internalLink, externalLink }) => {
              return (
                <Box
                  key={`banner-item-${id}`}
                  data-testid={id}
                  sx={{
                    width: "186px",
                    height: "213px",
                    borderRadius: "16px",
                  }}
                  component="img"
                  src={src[lang]}
                  onClick={() =>
                    handleBannerClick({ internalLink, externalLink })
                  }
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Homepage;
