import React from "react";
import { Box, Typography, Card, Skeleton } from "@mui/material";
import { styled, experimental_sx as sx } from "@mui/system";
import { useNavigate } from "react-router-dom";
import NftThumbnail from "../nftThumbnail/NftThumbnail";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NftActionModal from "./nftActionModal";
import { useState } from "react";
import { getFeatureFlags } from "../../../../config";
import axios from "axios";
import Vibrant from "node-vibrant";
import { Buffer } from "buffer";

const CardContainer = styled(Card)(
  sx({
    bgcolor: "background.default",
    border: "1px solid",
    borderColor: "grey.accent3",
    borderRadius: "16px",
    boxShadow: 0,
  }),
);

const NftCard = ({ collection, token, allTokens }) => {
  const navigate = useNavigate();

  const handleOnClickCard = () => {
    if (!open)
      navigate("/wallet/nfts/my-collection", {
        state: {
          collection,
          token,
          allTokens,
        },
      });
  };

  const [imageSkeletonWidth, setImageSkeletonWidth] = React.useState(0);
  const ref = React.useRef(null);
  const [open, setOpen] = useState(false);
  const [imgFileType, setImgFileType] = useState("");
  const [imgColor, setImgColor] = useState([]);

  React.useEffect(() => {
    if (ref.current) {
      setImageSkeletonWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  const CardSkeleton = () => {
    return (
      <>
        <CardContainer>
          <Box
            sx={{
              p: "12px",
            }}
          >
            <Skeleton
              ref={ref}
              variant="rectangular"
              width="100%"
              height={imageSkeletonWidth}
              sx={{
                border: "1px solid",
                borderColor: "grey.accent3",
                borderRadius: "16px",
                mb: "16px",
              }}
              data-testid="image-skeleton"
            />
            <Box>
              <Skeleton variant="text" width={"80px"} />
            </Box>
          </Box>
        </CardContainer>
      </>
    );
  };

  if (!token) {
    return <CardSkeleton />;
  }

  const loadFileInfo = async () => {
    if (token?.metadata?.animation_url) {
      setImgFileType("video");
    } else if (!imgFileType) {
      const response = await axios.get(
        token?.metadata?.image + `?timestamp=${new Date().getTime()}`,
        { responseType: "arraybuffer" },
      );
      const file = Buffer.from(response.data, "binary").toString("base64");
      const palette = await Vibrant.from(
        `data:${imgFileType};base64,` + file,
      ).getPalette();
      setImgColor(palette?.Vibrant.rgb);
      setImgFileType(response.headers["content-type"]);
    }
  };

  const { enableEinsteinSprint17 } = getFeatureFlags();

  return (
    <>
      <CardContainer>
        <Box
          onClick={handleOnClickCard}
          sx={{
            p: "12px",
          }}
        >
          <Box sx={{ mb: "16px" }}>
            <NftThumbnail
              token={token}
              collectionAddress={collection?.address}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle">{token.metadata?.name}</Typography>
            {enableEinsteinSprint17 && (
              <Box
                data-testid={`btn-see-action-${token.tokenID}-${collection.address}`}
                height="24px"
                width="24px"
                bgcolor="#000000"
                borderRadius="100%"
                color="#ffffff"
                sx={{ cursor: "pointer" }}
                onClick={event => {
                  event.stopPropagation();
                  loadFileInfo();
                  setOpen(true);
                }}
              >
                <MoreVertIcon />
              </Box>
            )}
          </Box>
          <NftActionModal
            open={open}
            handleClose={() => setOpen(false)}
            collection={collection}
            token={token}
            imgFileType={imgFileType}
            imgColor={imgColor}
          />
        </Box>
      </CardContainer>
    </>
  );
};

export default NftCard;
