import {
  Container,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import useNavigationHook from "hooks/useNavigation";
import useTranslation from "hooks/useTranslation";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ReactComponent as LineLogo } from "../../assets/icons/account-settings-icon/line.svg";
import GoogleLogo from "../../assets/images/google_logo.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "redux/store/configureStore";
import {
  fetchKeycloakAccount,
  resetKeycloakAccount,
} from "features/wallet/redux/slices/keycloak/keycloakAccount";
import {
  fetchKeycloakLinkedAccount,
  resetKeycloakLinkedAccount,
} from "features/wallet/redux/slices/keycloak/keycloakLinkedAccount";
import { Status } from "../../redux/constant";
import { phoneNumberSpaceSeparatorFormatter } from "utils/phoneNumberFormatter";
import ProfilePhotoBtn from "features/wallet/components/ProfilePhoto/ProfilePhotoBtn";
import useAuth from "hooks/useAuth";
import getLanguage from "utils/getLanguage";
import jwtDecode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import AuthContextService from "contexts/authContext/AuthContextServices";
import useKeycloakSwitch from "channel/useKeycloakSwitch";
import { EARTH_CHANNEL, FUSION_CHANNEL, getChannelConfig } from "config";
import { useNavigate, useSearchParams } from "react-router-dom";
import Prompt from "components/Prompt/Prompt";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import {
  fetchAsyncUnlinkAccount,
  resetUnlinkedAccount,
} from "features/wallet/redux/slices/accountSetting/unlinkedAccount";
import LoadingPrompt from "components/loadingPrompt/LoadingPrompt";

const ListContainer = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    marginTop: "8px",
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    borderRadius: 16,
    border: "1px solid",
    borderColor: theme.palette.grey.accent3,
    overflow: "hidden",
  };
});

function getHashBuffer(message) {
  const msgUint8 = new TextEncoder().encode(message);
  return crypto.subtle.digest("SHA-256", msgUint8);
}

function cleanedBase64(base64) {
  return base64.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
}
export const getUsername = (user, lang) => {
  const { firstNameEN, lastNameEN, firstNameTH, lastNameTH } = user;
  let firstname = "";
  let lastname = "";
  if (lang === "th") {
    firstname = firstNameTH || firstNameEN || "";
    lastname = lastNameTH || lastNameEN || "";
  } else {
    firstname = firstNameEN || firstNameTH || "";
    lastname = lastNameEN || lastNameTH || "";
  }
  return { firstname, lastname };
};

export const getLinkedAccountURL = async (token, provider) => {
  const { azp: clientId, session_state } = jwtDecode(token);
  const nonce = uuidv4();
  const input = nonce + session_state + clientId + provider;
  const check = await getHashBuffer(input);
  const buf = Buffer.from(check, "base64");
  const hash = cleanedBase64(buf.toString("base64"));
  const redirectUri = `${window.location.origin}/settings/account-settings?linked=${provider}`;
  return `${process.env.REACT_APP_AUTH_BASE_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/broker/${provider}/link?nonce=${nonce}&hash=${hash}&client_id=${clientId}&redirect_uri=${redirectUri}`;
};

const AccountSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { keycloak } = useKeycloakSwitch();
  const { user } = useAuth();
  const lang = getLanguage();
  const { t } = useTranslation("translation", { keyPrefix: "accountSettings" });
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [canEdit, setCanEdit] = useState(false);
  const [socialConnect, setSocialConnect] = useState({
    line: false,
    google: false,
  });

  const { data: keycloakAccount, status: keycloakAccountStatus } = useSelector(
    state => {
      return state.keycloakAccount;
    },
  );

  const { data: keycloakLinkedAccount, status: keycloakLinkedAccountStatus } =
    useSelector(state => state.keycloakLinkedAccount);

  const { status: unlinkedAccountStatus } = useSelector(
    state => state.unlinkedAccount,
  );

  const [selectedProvider, setSelectedProvider] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showOopModal, setShowOopModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const toaster = msg => {
    toast.remove();
    toast(t(msg), {
      style: {
        textAlign: "start",
        padding: "12px, 16px, 12px, 16px",
        color: "#FFFFFF",
        backgroundColor: "#141312",
        width: "100%",
        borderRadius: "16px",
      },
    });
  };

  const getAccessToken = () => {
    let accessToken = null;
    try {
      const tokenObj = AuthContextService.getTokenFromLocalStorage();
      const { access_token } = JSON.parse(tokenObj);
      accessToken = access_token;
    } catch (e) {}
    return accessToken;
  };

  const mutiChannelFunc = useMemo(
    () => ({
      [EARTH_CHANNEL]: {
        token: keycloak.token,
        isAuthenticated: keycloak.authenticated,
        login: () => {
          const locale = localStorage.getItem("lang") || "th";
          keycloak.login({ locale });
        },
      },
      [FUSION_CHANNEL]: {
        token: getAccessToken(),
        isAuthenticated: !!getAccessToken(),
        login: () => {
          window.location.replace("ascendmoney://closewebview");
        },
      },
    }),
    [keycloak],
  );

  const generateKeycloakLinkUrl = useCallback(getLinkedAccountURL, []);

  const handleUsernameClick = e => {
    e.preventDefault();
    if (canEdit) {
      navigate("/settings/account-settings/edit-username");
    }
  };

  const handleMobileNumberClick = e => {
    e.preventDefault();
    keycloak?.login({
      acr: {
        values: ["2"],
        essential: true,
      },
      prompt: "login",
      redirectUri: `${window.location.origin}/settings/account-settings/edit-mobile-number`,
    });
  };

  const handleConfirmModalClose = e => {
    e.preventDefault();
    setShowConfirmModal(false);
  };

  const handleOopModalClose = e => {
    e.preventDefault();
    setShowOopModal(false);
  };

  const handleConfirmUnlink = e => {
    e.preventDefault();
    setShowConfirmModal(false);
    dispatch(fetchAsyncUnlinkAccount({ providerName: selectedProvider }));
  };

  const navigateUserToLink = async name => {
    try {
      const url = await generateKeycloakLinkUrl(
        mutiChannelFunc[getChannelConfig().channel].token,
        name,
      );
      window.location.href = url;
    } catch (error) {
      console.error("error:", error);
    }
  };

  const handleSocialSwitchChange = e => {
    const { name, checked } = e.target;
    if (!checked) {
      // try to unlink
      if (socialConnect.google && socialConnect.line) {
        // both true can unlink one so show the confirm unlink modal here
        setShowConfirmModal(true);
        setSelectedProvider(name);
      } else {
        // some of them is not link and if user try to unlink it show Oop modal
        setShowOopModal(true);
      }
      return;
    }
    // try to link more
    navigateUserToLink(name);
  };

  useEffect(() => {
    setActiveMenu(true);
    dispatch(fetchKeycloakAccount());
    dispatch(fetchKeycloakLinkedAccount());
    return () => {
      dispatch(resetKeycloakAccount());
      dispatch(resetKeycloakLinkedAccount());
      dispatch(resetUnlinkedAccount());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!user.kycLevel) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [user.kycLevel]);

  useEffect(() => {
    if (unlinkedAccountStatus === Status.LOADING) {
      setShowLoading(true);
    }
    if (unlinkedAccountStatus === Status.LOADED) {
      setShowLoading(false);
      dispatch(fetchKeycloakLinkedAccount());
      toaster(`successfully_unlink_${selectedProvider}`);
      setSelectedProvider("");
    }
  }, [unlinkedAccountStatus]);

  useEffect(() => {
    if (
      keycloakLinkedAccountStatus === Status.LOADED &&
      keycloakLinkedAccount
    ) {
      setSocialConnect({
        line: keycloakLinkedAccount["line"][0].connected,
        google: keycloakLinkedAccount["google"][0].connected,
      });
    } else {
      setSocialConnect({ line: false, google: false });
    }
  }, [keycloakLinkedAccountStatus, keycloakLinkedAccount]);

  useEffect(() => {
    const linked = searchParams.get("linked");
    if (linked && keycloakLinkedAccountStatus === Status.LOADED) {
      if (keycloakLinkedAccount[linked][0].connected) {
        toaster(`toast_link_${linked}`);
        searchParams.delete("linked");
        setSearchParams(searchParams);
      }
    }
  }, [keycloakLinkedAccountStatus]);

  const { firstname, lastname } = getUsername(user, lang);
  const fullname = `${firstname} ${lastname}`;
  return (
    <>
      <Helmet>
        <title>{t("Account")}</title>
      </Helmet>
      <Container>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            margin: "24px 0 16px 0",
          }}
        >
          <ProfilePhotoBtn />
        </Box>
        <Typography variant="h6" sx={{ textAlign: "center", marginBottom: 3 }}>
          {fullname}
        </Typography>
        <ListContainer sx={{ marginBottom: 2 }}>
          <List sx={{ width: "100%", p: "0px" }}>
            <Box>
              <ListItemButton
                data-testid="name-btn"
                onClick={handleUsernameClick}
                TouchRippleProps={{ sx: { color: "primary.main" } }}
                sx={{ p: "16px 15px" }}
              >
                <ListItemText
                  primary={t("Name")}
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "grey.accent1",
                  }}
                />
                {fullname}
                {canEdit && (
                  <ListItemIcon sx={{ m: "0px" }}>
                    <ArrowForwardIosIcon
                      sx={{
                        height: "20px",
                        width: "20px",
                        color: "grey.accent1",
                      }}
                    />
                  </ListItemIcon>
                )}
              </ListItemButton>
              <Divider />
            </Box>
            <Box>
              <ListItemButton
                data-testid="mobile-btn"
                onClick={handleMobileNumberClick}
                TouchRippleProps={{ sx: { color: "primary.main" } }}
                sx={{ p: "16px 15px" }}
              >
                <ListItemText
                  primary={t("Mobile number")}
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "grey.accent1",
                  }}
                />

                {keycloakAccountStatus === Status.LOADED ? (
                  <Typography
                    variant="body2"
                    sx={{ color: "#868686", marginRight: 2 }}
                  >
                    {phoneNumberSpaceSeparatorFormatter(
                      keycloakAccount.attributes?.mobileNumber[0] || "",
                    )}
                  </Typography>
                ) : (
                  <Skeleton
                    data-testid="skl-mobile-number"
                    variant="text"
                    width="30%"
                    sx={{ margin: "auto" }}
                  />
                )}

                {canEdit && (
                  <ListItemIcon sx={{ m: "0px" }}>
                    <ArrowForwardIosIcon
                      sx={{
                        height: "20px",
                        width: "20px",
                        color: "grey.accent1",
                      }}
                    />
                  </ListItemIcon>
                )}
              </ListItemButton>
              <Divider />
            </Box>
            <Box>
              <ListItemButton disableRipple sx={{ p: "16px 15px" }}>
                <ListItemText
                  primary={t("Email")}
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "grey.accent1",
                  }}
                />

                <Typography
                  variant="body2"
                  sx={{ marginRight: 2, fontWeight: "600" }}
                >
                  {user.email || ""}
                </Typography>
              </ListItemButton>
            </Box>
          </List>
        </ListContainer>

        {canEdit && (
          <ListContainer>
            <List sx={{ width: "100%", p: "0px" }}>
              <Box>
                <ListItemButton disableRipple sx={{ p: "16px 15px" }}>
                  <Box component="img" sx={{ mr: "11px" }} src={GoogleLogo} />
                  <ListItemText
                    primary={t("Google")}
                    primaryTypographyProps={{
                      variant: "body2",
                      color: "grey.accent1",
                    }}
                  />
                  {keycloakLinkedAccountStatus === Status.LOADED ? (
                    <Switch
                      id="switch-google"
                      name="google"
                      data-testid="switch-google"
                      checked={socialConnect.google}
                      color="success"
                      onChange={handleSocialSwitchChange}
                    />
                  ) : (
                    <Skeleton
                      variant="text"
                      width="30%"
                      sx={{ margin: "auto" }}
                    />
                  )}
                </ListItemButton>
                <Divider />
              </Box>
              <Box>
                <ListItemButton disableRipple sx={{ p: "16px 15px" }}>
                  <ListItemIcon sx={{ mr: "11px" }}>
                    <LineLogo />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Line")}
                    primaryTypographyProps={{
                      variant: "body2",
                      color: "grey.accent1",
                    }}
                  />

                  {keycloakLinkedAccountStatus === Status.LOADED ? (
                    <Switch
                      id="switch-line"
                      name="line"
                      data-testid="switch-line"
                      checked={socialConnect.line}
                      color="success"
                      onChange={handleSocialSwitchChange}
                    />
                  ) : (
                    <Skeleton
                      variant="text"
                      width="30%"
                      sx={{ margin: "auto" }}
                    />
                  )}
                </ListItemButton>
                <Divider />
              </Box>
            </List>
          </ListContainer>
        )}
      </Container>
      {/* Confirm Modal */}
      {showConfirmModal && (
        <Prompt
          data-testid="confirm-modal"
          isVisible
          keyPrefix="accountSettings"
          title={`unlink_title_${selectedProvider}`}
          subTitle={`unlink_subtitle_${selectedProvider}`}
          buttonText="UNLINK"
          buttonText2="NOT NOW"
          onClose={handleConfirmUnlink}
          onClose2={handleConfirmModalClose}
        />
      )}

      {/* Oop Modal */}
      {showOopModal && (
        <Prompt
          data-testid="oop-modal"
          isVisible
          keyPrefix="accountSettings"
          title="Oops!"
          subTitle="You need to keep at least one account for login."
          buttonText="GOT IT"
          onClose={handleOopModalClose}
        />
      )}
      {/* Toast */}
      <Toaster position="bottom-center" reverseOrder={false}>
        {t => (
          <ToastBar toast={t}>
            {({ message }) => (
              <>
                <div>{message}</div>
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      {/* Loading  */}
      <LoadingPrompt
        isVisible={showLoading}
        keyPrefix="accountSettings"
        title="Processing"
      />
    </>
  );
};

export default AccountSettings;
