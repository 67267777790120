import {
  Avatar,
  Box,
  Button,
  Divider,
  Skeleton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useNavigationHook from "../../../../hooks/useNavigation";
import useTranslation from "../../../../hooks/useTranslation";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import CheckIcon from "../../../../assets/images/MyWallet/Check_Base.png";
import { formatDateTime } from "../../../../utils/dateTimeFormatter";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import { Status } from "../../../../redux/constant";
import { coinFormatter } from "../../../../utils/coinFormatter";
import { getValueByLanguage } from "../../../../utils/getLanguage";
import { trackCompletedPointConversion } from "../../../../mixpanel";
import { fetchAsyncCoins, resetCoins } from "../../redux/slices/coins/Coins";
import { abcLogo, abcUnit } from "features/wallet/utils/abc";
dayjs.extend(utc);
dayjs.extend(timezone);

export const ConvertCompleteLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  gap: 16px;
`;

export const DetailLine = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const DetailDivinder = styled(Divider)`
  width: 100%;
  border-color: #e1e1e1;
`;

const PartnerConvertPointComplete = () => {
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paramState = useLocation().state || {};
  const { coins, status: coinsStatus } = useSelector(state => state.coins);
  const { t } = useTranslation("translation", {
    keyPrefix: "convertCoins",
  });

  const {
    partnerId,
    amount,
    fromName,
    fromImageIconUrl,
    dateTime,
    transactionId,
    convertPoints,
    convertedCoins,
  } = paramState;

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    if (!partnerId) {
      navigate("/wallet");
    }
  }, [navigate, partnerId]);

  useEffect(() => {
    if (partnerId && convertPoints && convertedCoins) {
      trackCompletedPointConversion({
        partnerName: partnerId,
        sourceAmount: convertPoints,
        targetAmount: convertedCoins,
      });
    }
  }, [partnerId, convertPoints, convertedCoins]);

  useEffect(() => {
    if (!partnerId) return;
    dispatch(fetchAsyncCoins());

    return () => {
      dispatch(resetCoins());
    };
  }, [dispatch, partnerId]);

  const handleDoneClick = e => {
    e.preventDefault();
    navigate("/wallet");
  };

  const renderBalance = (status, balance) => {
    if (status === Status.LOADING) {
      return (
        <Skeleton
          animation="wave"
          width="50px"
          data-testid="balance-skeleton"
        />
      );
    } else if (
      status === Status.LOADED &&
      typeof balance !== "undefined" &&
      balance !== null
    ) {
      return (
        <Typography variant="subtitle">
          {coinFormatter(balance.toString())} {abcUnit}
        </Typography>
      );
    }
    return "";
  };

  if (!partnerId) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "32px 16px",
        height: "calc(100vh - 64px)",
      }}
      data-testid="partner-convert-point-complete"
    >
      <Helmet>
        <title>{t("Complete")}</title>
      </Helmet>
      <ConvertCompleteLayout>
        <Box component="img" src={CheckIcon} />
        <Typography id="successTitle" variant="subtitle">
          {t("Successfully Converted")}
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Box
            alt="Coins"
            component="img"
            src={abcLogo}
            sx={{
              objectFit: "cover",
              height: "24px",
              width: "24px",
              mr: "8px",
            }}
          />
          <Typography id="amountState" variant="h6">
            {amount} {abcUnit}
          </Typography>
        </Box>
        <DetailDivinder />
        <DetailLine>
          <Typography variant="body2" sx={{ color: "grey.accent2" }}>
            {t("From")}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Avatar
              alt={getValueByLanguage(fromName)}
              src={fromImageIconUrl}
              style={{
                width: "24px",
                height: "24px",
                marginRight: "8px",
                border: "1px solid #E1E1E1",
              }}
            />
            <Typography variant="body2" style={{ lineHeight: "24px" }}>
              {getValueByLanguage(fromName)}
            </Typography>
          </Box>
        </DetailLine>
        <DetailLine>
          <Typography variant="body2" sx={{ color: "grey.accent2" }}>
            {t("Date & Time")}
          </Typography>
          <Typography variant="body2">
            {formatDateTime(dateTime, "DD MMM YYYY, HH:mm")}
          </Typography>
        </DetailLine>
        <DetailLine>
          <Typography variant="body2" sx={{ color: "grey.accent2" }}>
            {t("Transaction ID")}
          </Typography>
          <Typography
            variant="body2"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "120px",
            }}
          >
            {transactionId}
          </Typography>
        </DetailLine>
        <DetailDivinder />
        <DetailLine style={{ marginTop: "8px" }}>
          <Typography variant="body2" sx={{ color: "grey.accent2" }}>
            {t("Total balance")}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box
              alt="Coins"
              component="img"
              src={abcLogo}
              sx={{
                objectFit: "cover",
                height: "24px",
                width: "24px",
                mr: "8px",
              }}
            />
            {renderBalance(coinsStatus, coins?.balance)}
          </Box>
        </DetailLine>
      </ConvertCompleteLayout>
      <Button
        id="done-btn"
        data-testid="done-btn"
        variant="contained"
        sx={{ height: 48, width: "100%", borderRadius: "24px" }}
        fullWidth
        onClick={handleDoneClick}
      >
        {t("Done")}
      </Button>
    </Box>
  );
};

export default PartnerConvertPointComplete;
