import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import GenericErrorImg from "../../../../assets/images/GenericErrorImg.svg";
import ViewImageDialog from "./ViewImageDialog";
import Vibrant from "node-vibrant";
import { getFeatureFlags } from "../../../../config";

const NftAsset = ({ token }) => {
  const [bgColor, setBgColor] = useState({
    lightRgb: [],
    middleRgb: [],
    darkRgb: [],
  });
  const [open, setOpen] = useState(false);
  const { enableNftSprint16 } = getFeatureFlags();

  const initBgColor = async () => {
    const palette = await Vibrant.from(
      token?.metadata?.image + `?timestamp=${new Date().getTime()}`,
    ).getPalette();
    if (palette?.Vibrant)
      setBgColor({
        lightRgb: palette.Vibrant.rgb.map(v => v * 2),
        middleRgb: palette.Vibrant.rgb,
        darkRgb: palette.Vibrant.rgb.map(v => v / 2),
      });
  };

  useEffect(() => {
    if (enableNftSprint16) initBgColor();
  }, [token]);

  const gradientBg = `linear-gradient(rgb(${bgColor.lightRgb.join(
    ",",
  )}) 0% ,rgb(${bgColor.middleRgb.join(",")}) 100%)`;

  const heightWithFlag = enableNftSprint16 && "375px";
  const widthWithFlag = enableNftSprint16 ? "375px" : "100%";

  return (
    <>
      {token?.metadata?.animation_url ? (
        <Box
          textAlign="center"
          height={heightWithFlag}
          sx={
            enableNftSprint16 && {
              background: gradientBg,
            }
          }
        >
          <video
            data-testid="video-nft"
            width={widthWithFlag}
            height={heightWithFlag}
            autoPlay
            loop
            controls
            controlsList="nodownload"
            playsInline
          >
            <source
              src={token?.metadata?.animation_url}
              type="video/mp4"
              data-testid="nft-video-source"
            />
            Your mobile does not support the video tag.
          </video>
        </Box>
      ) : (
        <Box
          textAlign="center"
          height={heightWithFlag}
          sx={
            enableNftSprint16 && {
              background: gradientBg,
            }
          }
        >
          <Box
            data-testid="img-nft"
            alt="NFT image"
            component="img"
            src={token?.metadata?.image}
            sx={{
              width: widthWithFlag,
              height: heightWithFlag,
              border: "1px solid",
              borderColor: "grey.accent3",
              borderRadius: "16px",
              mb: "24px",
            }}
            onClick={() => (enableNftSprint16 ? setOpen(true) : null)}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = GenericErrorImg;
            }}
          />
        </Box>
      )}
      <ViewImageDialog
        url={token?.metadata?.image}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default NftAsset;
