import axios from "axios";
import jwtDecode from "jwt-decode";
// import MockAdapter from "axios-mock-adapter";
// import mockApis from "../__fakeAPI__/customerApi";
import getLanguage from "../utils/getLanguage";
import AuthContextServices from "../contexts/authContext/AuthContextServices";
import { authConfig, apiEndpoint } from "../config";

const AuthApi = axios.create({
  baseURL: apiEndpoint.keycloak,
  headers: {
    "content-type": "application/json",
  },
});

AuthApi.interceptors.request.use(async (config) => {
  const token = AuthContextServices.getTokenFromLocalStorage();

  if (config.url === "protocol/openid-connect/token") {
    return config;
  }

  if (token) {
    const { access_token, refresh_token } = JSON.parse(token);
    const { exp } = jwtDecode(access_token);
    const now = Math.floor(Date.now() / 1000);

    const isExpired = now > exp;

    if (isExpired) {
      const response = await axios.post(
        `${apiEndpoint.keycloakProtocol}/openid-connect/token`,
        new URLSearchParams({
          client_id: authConfig.client_id,
          refresh_token,
          grant_type: "refresh_token",
        }),
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      );

      AuthContextServices.setLocalStorageToken(JSON.stringify(response.data));

      config.headers["Authorization"] = `Bearer ${response.data.access_token}`;
      config.headers["Accept-language"] = getLanguage() ?? "en";
      return config;
    }

    config.headers["Authorization"] = `Bearer ${access_token}`;
    config.headers["Accept-language"] = getLanguage() ?? "en";
    return config;
  }
  return config;
});

// mockApis(new MockAdapter(AuthApi));

export default AuthApi;
