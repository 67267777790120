import React, { useEffect, useState } from "react";
import {
  AppBar,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Link,
  Grid,
  Typography,
  useMediaQuery,
  Box,
  Container,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as NavbarABCLogo } from "../../assets/icons/navbar_abc_logo.svg";
import { ReactComponent as ABCWalletLogo } from "../../assets/images/OnBoarding/abc_wallet_logo.svg";
import { ReactComponent as HelpLogo } from "../../assets/images/OnBoarding/help_sign.svg";
import useTranslation from "../../hooks/useTranslation";
import { openFAQsLink } from "../../pages/settings/Settings";
import { useTheme } from "@mui/styles";
import { isEarth, isFusion } from "../../config";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import SettingBtn from "pages/settings/SettingBtn";
import useDrawerMenu from "hooks/useDrawerMenu";
import { IBMPlexBtn } from "pages/onboarding/EarthOnboarding";
import USflag from "../../assets/images/OnBoarding/US.svg";
import THflag from "../../assets/images/OnBoarding/TH.svg";
import useAuth from "hooks/useAuth";
import { authStatus } from "contexts/authContext/AuthContext";

const getTemplateColumn = (activeDropdownLanguage, activeMenu) => {
  if (activeDropdownLanguage) {
    return "1fr 1fr 1fr";
  }
  if (!activeMenu && !activeDropdownLanguage) {
    return "1fr 2fr 1fr";
  }
  return "1fr";
};

const NavBar = ({
  activeDropdownLanguage,
  activeMenu,
  level,
  activeDrawerMenu,
  keycloak,
  earthNavbar,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [lang, setLang] = useState(localStorage.getItem("lang") || "th");
  const { initializeTranslation, t } = useTranslation("translation", {
    keyPrefix: "onboarding",
  });
  const handleTitleClick = () => {
    navigate("/");
  };
  const [title, setTitle] = useState("");
  const utheme = useTheme();
  const matchDownMD = useMediaQuery(utheme.breakpoints.down("md"));
  const setActiveDrawerMenu = useDrawerMenu();
  const { status, initialize } = useAuth();

  const handleSignup = () => {
    const lang = localStorage.getItem("lang") || "th";
    if (status === authStatus.GUEST) {
      navigate("/");
      keycloak?.login({ locale: lang });
      return;
    }

    if (status === authStatus.ERROR) {
      initialize();
      navigate("/");
      return;
    }
    navigate("/terms-and-conditions");
  };

  const handleLanguageChange = event => {
    const selectedLang = event.target.value;
    setLang(selectedLang);
    navigate({
      pathname: "/onboarding",
      search: `?lang=${selectedLang}`,
    });
    initializeTranslation();
  };

  const handleFlagClick = e => {
    e.preventDefault();
    const newLang = lang === "th" ? "en" : "th";
    setLang(newLang);
    navigate({
      pathname: "/onboarding",
      search: `?lang=${newLang}`,
    });
    initializeTranslation();
  };

  const handleBack = () => {
    if (activeDrawerMenu) {
      // if user still open the drawer just close it
      setActiveDrawerMenu(false);
      return;
    }
    if (location.pathname === "/convert") {
      navigate({ pathname: "/" });
      return;
    }
    if (window.history.state.idx <= 0) {
      window.location.replace("ascendmoney://closewebview");
      return;
    }

    navigate(-1);
  };

  const handleClose = () => {
    window.location.replace("ascendmoney://closewebview");
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (document.title !== title) {
        setTitle(document.title);
      }
    }, 50);
    return () => {
      clearInterval(intervalId);
    };
  }, [title]);

  if (
    earthNavbar === "onboarding" &&
    isEarth &&
    (location.pathname === "/" || location.pathname === "/onboarding")
  ) {
    return (
      <AppBar
        data-testid="earth-navbar"
        position="sticky"
        sx={{
          height: {
            xs: 60,
            md: 80,
          },
          gridArea: "navbar",
          backgroundColor: "background.paper",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          boxShadow: "none",
          borderBottom: "1px solid ",
          borderBottomColor: "grey.accent4",
        }}
      >
        <Container sx={{ display: "flex", alignItems: "center" }}>
          <Box
            id="toolbar-tb"
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <IconButton
                id="navbar-home-btn"
                size="large"
                sx={{
                  justifyContent: "flex-start",
                  padding: 0,
                  margin: 0,
                }}
                onClick={handleTitleClick}
              >
                <ABCWalletLogo />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: {
                  xs: 2,
                  md: 3,
                },
                alignItems: "center",
                height: {
                  xs: "32px",
                  md: "40px",
                },
              }}
            >
              {matchDownMD ? (
                <IconButton
                  data-testid="help-btn"
                  href="https://walletsupport.ascendbit.net"
                  target="_blank"
                  sx={{
                    padding: 0,
                    width: {
                      xs: "40px",
                      md: "48px",
                    },
                  }}
                >
                  <HelpLogo
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </IconButton>
              ) : (
                <IBMPlexBtn
                  data-testid="help-btn"
                  href="https://walletsupport.ascendbit.net"
                  target="_blank"
                  variant="outlined"
                  sx={{
                    height: "100%",
                  }}
                >
                  {t("NEED HELP?")}
                </IBMPlexBtn>
              )}

              <IconButton
                data-testid="flag-btn"
                sx={{
                  padding: 0,
                  width: {
                    xs: "32px",
                    md: "40px",
                  },
                }}
                onClick={handleFlagClick}
              >
                <Box
                  component="img"
                  sx={{ width: "100%" }}
                  src={lang === "th" ? THflag : USflag}
                />
              </IconButton>
              <IBMPlexBtn
                data-testid="navbar-login-btn"
                sx={{
                  height: "100%",
                  padding: "4px 24px",
                  boxSizing: "content-box",
                }}
                onClick={handleSignup}
                variant="contained"
              >
                {t("LOG IN")}
              </IBMPlexBtn>
            </Box>
          </Box>
        </Container>
      </AppBar>
    );
  }

  if (isFusion) {
    return (
      <AppBar
        position="sticky"
        sx={{
          gridArea: "navbar",
          backgroundColor: "background.paper",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          boxShadow: "none",
          borderBottom: "1px solid ",
          borderBottomColor: "grey.accent4",
        }}
      >
        <IconButton
          data-testid="nav-bar-back-btn"
          id="nav-bar-back-btn"
          size="small"
          onClick={handleBack}
        >
          <ArrowBackIosNewIcon style={{ fontSize: 20 }} />
        </IconButton>
        <Typography
          variant="h6"
          color="text.primary"
          sx={{ display: "flex", alignItems: "center" }}
        >
          {title}
        </Typography>

        <IconButton
          data-testid="nav-bar-close-btn"
          id="nav-bar-close-btn"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </AppBar>
    );
  }

  if (level > 1 && isEarth && matchDownMD) {
    return (
      <AppBar
        data-testid="navbar"
        position="sticky"
        sx={{
          gridArea: "navbar",
          backgroundColor: "background.paper",
          boxShadow: "none",
          borderBottom: "1px solid ",
          borderBottomColor: "grey.accent4",
        }}
      >
        <Toolbar
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr auto",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <IconButton
              id="navbar-home-btn"
              size="large"
              sx={{
                justifyContent: "flex-start",
                padding: 0,
                margin: 0,
              }}
              onClick={handleTitleClick}
            >
              <NavbarABCLogo />
            </IconButton>
          </Box>

          <Typography
            data-testid="title-txt"
            variant="h6"
            color="text.primary"
            sx={{
              textAlign: "center",
            }}
          >
            {title}
          </Typography>

          {activeMenu ? (
            <Box sx={{ textAlign: "end" }}>
              <SettingBtn activeDrawerMenu={activeDrawerMenu} isNavbar />
            </Box>
          ) : activeDropdownLanguage ? (
            ""
          ) : (
            <div />
          )}

          {activeDropdownLanguage && (
            <Grid container sx={{ alignItems: "baseline", gap: 2 }}>
              <Link
                href="#"
                underline="none"
                color={"black"}
                onClick={openFAQsLink}
                sx={{ marginLeft: "auto" }}
              >
                FAQ
              </Link>
              <Select
                data-testid="navbar-select"
                value={lang}
                onChange={handleLanguageChange}
                variant="standard"
                disableUnderline
              >
                <MenuItem data-testid="navbar-th-munuitem" value={"th"}>
                  ไทย
                </MenuItem>
                <MenuItem data-testid="navbar-en-munuitem" value={"en"}>
                  English
                </MenuItem>
              </Select>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
    );
  }
  return (
    <AppBar
      position="sticky"
      sx={{
        gridArea: "navbar",
        backgroundColor: "background.paper",
        boxShadow: "none",
        borderBottom: "1px solid ",
        borderBottomColor: "grey.accent4",
      }}
    >
      <Toolbar
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr 1fr 1fr",
            sm: "1fr 1fr 1fr",
            md: getTemplateColumn(activeDropdownLanguage, activeMenu),
            lg: getTemplateColumn(activeDropdownLanguage, activeMenu),
            xl: getTemplateColumn(activeDropdownLanguage, activeMenu),
          },
          justifyContent: "space-between",
        }}
      >
        {activeDropdownLanguage || matchDownMD || !activeMenu ? (
          <Box>
            <IconButton
              id="navbar-home-btn"
              size="large"
              sx={{
                justifyContent: "flex-start",
                padding: 0,
                margin: 0,
              }}
              onClick={handleTitleClick}
            >
              <NavbarABCLogo />
            </IconButton>
          </Box>
        ) : (
          ""
        )}

        <Typography
          data-testid="title-txt"
          variant="h6"
          color="text.primary"
          sx={{
            textAlign:
              matchDownMD || activeDropdownLanguage || !activeMenu
                ? "center"
                : "left",
          }}
        >
          {title}
        </Typography>

        {activeDrawerMenu && matchDownMD && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <SettingBtn activeDrawerMenu isNavbar />
          </Box>
        )}

        {activeDropdownLanguage && (
          <Grid container sx={{ alignItems: "baseline" }}>
            <Link
              href="#"
              underline="none"
              color={"black"}
              onClick={openFAQsLink}
              sx={{ marginLeft: "auto" }}
            >
              FAQ
            </Link>
            <Select
              data-testid="navbar-select"
              value={lang}
              onChange={handleLanguageChange}
              variant="standard"
              disableUnderline
              sx={{ marginLeft: "24px" }}
            >
              <MenuItem data-testid="navbar-th-munuitem" value={"th"}>
                ไทย
              </MenuItem>
              <MenuItem data-testid="navbar-en-munuitem" value={"en"}>
                English
              </MenuItem>
            </Select>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
