import React, { Suspense } from "react";
import GuestLayout from "./layouts/GuestLayout";
import UserLayout from "./layouts/UserLayout";
import useAuth from "./hooks/useAuth";
import LoadingScreen from "./components/loadingScreen/LoadingScreen";
import ErrorBoundary from "./components/error-boundary/error-boundary";
import { Navigate } from "react-router-dom";
import { authStatus } from "./contexts/authContext/AuthContext";
import AuthContextService from "./contexts/authContext/AuthContextServices";
import { getFeatureFlags, isFusion } from "./config";

import { default as NotFoundComp } from "./pages/notFound/NotFound";
import { default as ServerErrorComp } from "./pages/serverError/ServerError";
import { default as AuthorizationErrorComp } from "./pages/authorizationError/AuthorizationError";
import { default as SessionExpiredErrorComp } from "./pages/sessionExpiredError/SessionExpiredError";
import { default as OnboardingComp } from "./pages/onboarding/Onboarding";
import { default as TermsAndConditionsComp } from "./pages/termsAndConditions/TermsAndConditions";
import { default as ConsentComp } from "./pages/consent/Consent";
import { default as HomepageComp } from "./pages/home/Homepage";
import { default as SearchDealsComp } from "./features/deals/pages/searchDeals/SearchDeals";
import { default as SettingsComp } from "./pages/settings/Settings";
import { default as PrivacyPolicyComp } from "./pages/privacyPolicy/PrivacyPolicy";
import { default as HelpComp } from "./pages/help/Help";
import { default as WalletComp } from "./pages/wallet/Wallet";
import { default as MarketplaceComp } from "./pages/marketplace/Marketplace";
import { default as GenericErrorComp } from "./pages/genericError/GenericError";
import { default as DetailedDealsComp } from "./features/deals/pages/detailedDeals/DetailedDeals";
import { default as MerchantDealsComp } from "./features/deals/pages/merchantDeals/MerchantDeals";
import { default as TransactionDetailsComp } from "./features/wallet/components/transactionDetails/TransactionDetails";
import { default as RedemptionSummaryComp } from "./features/deals/pages/redemptionSummary/RedemptionSummary";
import { default as SuccessRedemptionComp } from "./features/deals/pages/successRedemption/SuccessRedemption";
import { default as SendCoinComp } from "./features/wallet/pages/sendCoin/SendCoin";
import { default as VoucherDetailComp } from "./features/wallet/pages/voucherDetail/VoucherDetail";
import { default as TermsMenuComp } from "./pages/termsMenu/TermsMenu";
import { default as MyWalletComp } from "./features/wallet/components/myWallet/MyWallet";
import { default as MyVoucherComp } from "./features/wallet/components/myVoucher/MyVoucher";
import { default as ConvertCoinsComp } from "./features/wallet/pages/convertCoins/ConvertCoins";
import { default as ReviewConvertComp } from "./features/wallet/pages/reviewConvert/ReviewConvert";
import { default as ConvertCoinsCompleteComp } from "./features/wallet/pages/convertCoinsComplete/ConvertCoinsComplete";
import { default as NftComp } from "./features/wallet/pages/nft/Nft";
import { default as NftMyCollectionComp } from "./features/wallet/pages/nftMyCollection/NftMyCollection";
import { default as MyNftComp } from "./features/wallet/components/myNft/MyNft";
import { default as TransferCoinComp } from "./features/wallet/pages/transferCoin/transferCoin";
import { default as TransferCoinReviewComp } from "./features/wallet/pages/transferCoinReview/transferCoinReview";
import { default as TransferCoinCompleteComp } from "./features/wallet/pages/transferCoinComplete/transferCoinComplete";
import { default as NftDetailComp } from "./features/wallet/pages/nftDetail/NftDetail";
import { default as SendNftComp } from "./features/wallet/pages/sendNft/SendNft";
import { default as ReviewSendNftComp } from "./features/wallet/pages/reviewSendNft/ReviewSendNft";
import { default as SendNftCompleteComp } from "./features/wallet/pages/sendNftComplete/SendNftComplete";
import { default as ConvertLandingPageComp } from "./features/wallet/pages/convertLandingPage/ConvertLandingPage";
import { default as PartnerConvertCoinsComp } from "./features/wallet/pages/partnerConvertCoins/PartnerConvertCoins";
import { default as PartnerReviewConvertComp } from "./features/wallet/pages/partnerReviewConvert/PartnerReviewConvert";
import { default as PartnerConvertPointCompleteComp } from "./features/wallet/pages/partnerConvertPointComplete/PartnerConvertPointComplete";
import { default as VerifyLinkComp } from "./features/wallet/pages/verifyLink/VerifyLink";
import { default as PartnerConvertInComp } from "./features/wallet/pages/partnerConvertIn/PartnerConvertIn";
import { default as AccountSettingsComp } from "./pages/accountSettings/AccountSettings";
import { default as PartnerAllYourRewardComp } from "./features/wallet/pages/partnerAllYourReward/PartnerAllYourReward";
import { default as ProfilePhotoUploadScreenComp } from "./pages/accountSettings/ProfilePhotoUploadScreen";
import { default as ManageLinkedRewardsComp } from "./features/wallet/pages/manageLinkedRewards/ManageLinkedRewards";
import { default as BuyCoinsComp } from "./features/wallet/pages/buyCoins/BuyCoins";
import { default as MarketplaceHomeComp } from "./pages/marketplace/MarketplaceHome";
import { default as EnterAccountComp } from "./features/wallet/pages/buyCoins/enterAccount/EnterAccount";
import { default as ConfirmPurchaseComp } from "./features/wallet/pages/buyCoins/confirmPurchase/ConfirmPurchase";
import { default as BuyCoinsCompleteComp } from "./features/wallet/pages/buyCoins/buyCoinsComplete/BuyCoinsComplete";
import { default as BridgeNftComp } from "./features/wallet/pages/bridgeNftOnboarding/BridgeNftOnboarding";
import { default as BridgeNftDetailComp } from "./features/wallet/pages/bridgeDetail/BridgeDetail";
import { default as BridgeReviewComp } from "./features/wallet/pages/bridgeReview/BridgeReview";
import { default as EditUsernameComp } from "./pages/accountSettings/EditUsername";
import { default as EditMobileNumberComp } from "./pages/accountSettings/EditMobileNumber";
import { default as SendOTPComp } from "./pages/accountSettings/SendOTP";
const Loadable = Component => props =>
  (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    </ErrorBoundary>
  );

//Pages
export const NotFound = Loadable(NotFoundComp);
export const ServerError = Loadable(ServerErrorComp);
export const AuthorizationError = Loadable(AuthorizationErrorComp);
export const SessionExpiredError = Loadable(SessionExpiredErrorComp);
export const Onboarding = Loadable(OnboardingComp);
export const TermsAndConditions = Loadable(TermsAndConditionsComp);
export const Consent = Loadable(ConsentComp);
export const Homepage = Loadable(HomepageComp);
export const SearchDeals = Loadable(SearchDealsComp);
export const Settings = Loadable(SettingsComp);
export const PrivacyPolicy = Loadable(PrivacyPolicyComp);
export const Help = Loadable(HelpComp);
export const Wallet = Loadable(WalletComp);
export const Marketplace = Loadable(MarketplaceComp);
export const MarketplaceHome = Loadable(MarketplaceHomeComp);
export const GenericError = Loadable(GenericErrorComp);
export const DetailedDeals = Loadable(DetailedDealsComp);
export const MerchantDeals = Loadable(MerchantDealsComp);
export const TransactionDetails = Loadable(TransactionDetailsComp);
export const RedemtionSummary = Loadable(RedemptionSummaryComp);
export const SuccessRedemption = Loadable(SuccessRedemptionComp);
export const SendCoin = Loadable(SendCoinComp);
export const VoucherDetail = Loadable(VoucherDetailComp);
export const TermsMenu = Loadable(TermsMenuComp);
export const MyWallet = Loadable(MyWalletComp);
export const MyVoucher = Loadable(MyVoucherComp);
export const ConvertCoins = Loadable(ConvertCoinsComp);
export const ReviewConvert = Loadable(ReviewConvertComp);
export const ConvertCoinsComplete = Loadable(ConvertCoinsCompleteComp);
export const Nft = Loadable(NftComp);
export const NftMyCollection = Loadable(NftMyCollectionComp);
export const MyNft = Loadable(MyNftComp);
export const TransferCoin = Loadable(TransferCoinComp);
export const TransferCoinReview = Loadable(TransferCoinReviewComp);
export const NftDetail = Loadable(NftDetailComp);
export const SendNft = Loadable(SendNftComp);
export const ReviewSendNft = Loadable(ReviewSendNftComp);
export const SendNftComplete = Loadable(SendNftCompleteComp);
export const TransferCoinComplete = Loadable(TransferCoinCompleteComp);
export const ConvertLandingPage = Loadable(ConvertLandingPageComp);
export const PartnerConvertCoins = Loadable(PartnerConvertCoinsComp);
export const PartnerReviewConvert = Loadable(PartnerReviewConvertComp);
export const PartnerConvertPointComplete = Loadable(
  PartnerConvertPointCompleteComp,
);
export const VerifyLink = Loadable(VerifyLinkComp);
export const PartnerConvertIn = Loadable(PartnerConvertInComp);
export const PartnerAllYourReward = Loadable(PartnerAllYourRewardComp);
export const AccountSetting = Loadable(AccountSettingsComp);
export const EditUsername = Loadable(EditUsernameComp);
export const EditMobileNumber = Loadable(EditMobileNumberComp);
export const SendOTP = Loadable(SendOTPComp);
export const ProfilePhotoUploadScreen = Loadable(ProfilePhotoUploadScreenComp);
export const ManageLinkedRewards = Loadable(ManageLinkedRewardsComp);
export const BuyCoins = Loadable(BuyCoinsComp);
export const EnterAccount = Loadable(EnterAccountComp);
export const ConfirmPurchase = Loadable(ConfirmPurchaseComp);
export const BuyCoinsComplete = Loadable(BuyCoinsCompleteComp);
export const BridgeNft = Loadable(BridgeNftComp);
export const BridgeNftDetail = Loadable(BridgeNftDetailComp);
export const BridgeReview = Loadable(BridgeReviewComp);

const Routes = keycloak => {
  const { token, status } = useAuth();
  const {
    enablePartnerDirectUrl,
    enableF2C,
    enableAccountSetting,
    enableNftBridge,
  } = getFeatureFlags();

  if (token && status === authStatus.LOGGED_IN) {
    return [
      {
        path: "",
        element: <UserLayout />,
        children: [
          {
            path: "/",
            element: <Homepage />,
          },
          {
            path: "/wallet",
            element: <Wallet />,
            children: [
              { path: "", element: <MyWallet /> },
              { path: "my-vouchers", element: <MyVoucher /> },
              {
                path: "nfts",
                element: <Nft />,
                children: [
                  { path: "", element: <NftMyCollection /> },
                  { path: "other-collectibles", element: <MyNft /> },
                ],
              },
            ],
          },
          {
            path: "/marketplace",
            element: <Marketplace />,
            children: [
              { path: "", element: <MarketplaceHome /> },
              { path: "my-vouchers", element: <MyVoucher /> },
            ],
          },
          {
            path: "/marketplace/deal/:id",
            element: <DetailedDeals />,
          },
          {
            path: "/marketplace/deal/:id/summary",
            element: <RedemtionSummary />,
          },
          {
            path: "/marketplace/deal/:id/success",
            element: <SuccessRedemption />,
          },
          {
            path: "/marketplace/merchant/:id",
            element: <MerchantDeals />,
          },
          {
            path: "/wallet/transaction/:id",
            element: <TransactionDetails />,
          },
          {
            path: "/wallet/voucher/:id",
            element: <VoucherDetail />,
          },
          {
            path: "/wallet/nfts/my-collection",
            element: <NftDetail />,
          },
          {
            path: "/wallet/nfts/my-collection/send-nft",
            element: <SendNft />,
          },
          {
            path: "/wallet/nfts/my-collection/review-send-nft",
            element: <ReviewSendNft />,
          },
          {
            path: "/wallet/nfts/my-collection/complete-send-nft",
            element: <SendNftComplete />,
          },
          !!enableNftBridge && {
            path: "/wallet/nfts/my-collection/bridge-nft",
            element: <BridgeNft />,
          },
          !!enableNftBridge && {
            path: "/wallet/nfts/my-collection/bridge-detail-nft",
            element: <BridgeNftDetail />,
          },
          !!enableNftBridge && {
            path: "/wallet/nfts/my-collection/bridge-review-nft",
            element: <BridgeReview />,
          },

          {
            path: "/sendcoin",
            element: <SendCoin />,
          },
          {
            path: "/convert",
            element: <ConvertCoins />,
          },
          {
            path: "/review",
            element: <ReviewConvert />,
          },
          {
            path: "/convert-complete",
            element: <ConvertCoinsComplete />,
          },
          !!enablePartnerDirectUrl && {
            path: "/wallet/partner-convert-in",
            element: <PartnerConvertIn />,
          },
          !!enablePartnerDirectUrl && {
            path: "/wallet/partner-all-your-reward",
            element: <PartnerAllYourReward />,
          },
          !!enablePartnerDirectUrl && {
            path: "/partner-convert/:partnerId",
            element: <PartnerConvertCoins />,
          },
          !!enablePartnerDirectUrl && {
            path: "/partner-review/:partnerId",
            element: <PartnerReviewConvert />,
          },
          !!enablePartnerDirectUrl && {
            path: "/partner-convert-complete",
            element: <PartnerConvertPointComplete />,
          },
          {
            path: "/transfer",
            element: <TransferCoin />,
          },
          {
            path: "/transfer-review",
            element: <TransferCoinReview />,
          },
          {
            path: "/transfer-complete",
            element: <TransferCoinComplete />,
          },
          !!enableF2C && {
            path: "/wallet/buy-coins",
            element: <BuyCoins />,
          },
          !!enableF2C && {
            path: "/wallet/buy-coins/account",
            element: <EnterAccount />,
          },
          !!enableF2C && {
            path: "/wallet/buy-coins/confirm-purchase",
            element: <ConfirmPurchase />,
          },
          !!enableF2C && {
            path: "/wallet/buy-coins/complete",
            element: <BuyCoinsComplete />,
          },
          {
            path: "/settings",
            element: <Settings />,
          },
          {
            path: "/settings/account-settings",
            element: enableAccountSetting ? <AccountSetting /> : <div />,
          },
          {
            path: "/settings/account-settings/edit-username",
            element: enableAccountSetting ? <EditUsername /> : <div />,
          },
          {
            path: "/settings/account-settings/edit-mobile-number",
            element: enableAccountSetting ? <EditMobileNumber /> : <div />,
          },
          {
            path: "/settings/account-settings/send-otp",
            element: enableAccountSetting ? <SendOTP /> : <div />,
          },
          !!enablePartnerDirectUrl && {
            path: "/settings/linked-rewards",
            element: <ManageLinkedRewards />,
          },
          {
            path: "settings/profile-photo",
            element: <ProfilePhotoUploadScreen />,
          },
          {
            path: "/settings/privacy-settings",
            element: <Consent />,
          },
          {
            path: "settings/terms-and-conditions",
            element: <TermsMenu />,
          },
          {
            path: "settings/privacy-policy",
            element: <PrivacyPolicy />,
          },
          {
            path: "/help",
            element: <Help />,
          },
          {
            path: "/server-error",
            element: <ServerError />,
          },
          {
            path: "/auth-error",
            element: <SessionExpiredError />,
          },
          {
            path: "*",
            element: <Navigate replace to="/" />,
          },
        ],
      },
      {
        path: "/consent",
        element: <Consent />,
      },
      !!enablePartnerDirectUrl && {
        path: "/convert/:partnerId",
        element: <ConvertLandingPage />,
      },
      !!enablePartnerDirectUrl && {
        path: "/verify-link/:partnerId",
        element: <VerifyLink />,
      },
    ];
  } else {
    return [
      {
        path: "",
        element: <GuestLayout />,
        children: [
          {
            path: "/",
            element:
              status === authStatus.TOS_REQUIRED ? (
                <Navigate replace to="/terms-and-conditions" />
              ) : status === authStatus.ONBOARDING ? (
                <Onboarding />
              ) : status === authStatus.INACTIVE ? (
                <GenericError
                  keyPrefix={`${
                    isFusion ? "inactiveErrorFusion" : "inactiveErrorEarth"
                  }`}
                  title="title"
                  body="desc"
                  buttonText="Ok"
                  navigateTo=""
                  redirect={() => {
                    if (AuthContextService.getTmwTokenRefFromLocalStorage()) {
                      window.location.replace("ascendmoney://closewebview");
                    } else {
                      keycloak?.logout();
                      AuthContextService.clearTokenFromLocalStorage();
                    }
                  }}
                  center={true}
                />
              ) : status === authStatus.ERROR ? (
                <Navigate replace to="/unhandled-exception" />
              ) : (
                <Onboarding />
              ),
          },
          {
            path: "/terms-and-conditions",
            element: <TermsAndConditions />,
          },
          {
            path: "/unhandled-exception",
            element:
              status === authStatus.SIGN_UP_EXCEPTION_ERROR ? (
                <GenericError
                  keyPrefix="signupExceptionCaseError"
                  title="You already have an account."
                  body="Try to log in with your existing account to continue."
                  buttonText="Ok"
                  navigateTo="/onboarding"
                  center={true}
                  isHideMenu
                />
              ) : (
                <GenericError
                  title="Service is not available"
                  body="Service is not available at the moment. You may be able to try again."
                  buttonText="Ok"
                  navigateTo="/onboarding"
                  center={true}
                  isHideMenu
                />
              ),
          },
          {
            path: "/onboarding",
            element: <Onboarding />,
          },
          !!enablePartnerDirectUrl && {
            path: "/convert/:partnerId",
            element: <ConvertLandingPage />,
          },
          !!enablePartnerDirectUrl && {
            path: "/verify-link/:partnerId",
            element: <VerifyLink />,
          },
          {
            path: "/*",
            element: <Navigate replace to="/onboarding" />,
          },
        ],
      },
    ];
  }
};
export default Routes;
