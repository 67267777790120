import React, { useState } from "react";
import { Box, Button, Container, styled, Typography } from "@mui/material";
import SplitTopBottomLayout from "components/SplitTopBottomLayout/SplitTopBottomLayout";
import useTranslation from "hooks/useTranslation";
import OtpField from "components/otpField/OtpField";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { useCountdown, useEffectOnce } from "usehooks-ts";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";

dayjs.extend(duration);
dayjs.extend(utc);

const ResendBtn = styled(Button)(({ theme }) => ({
  fontWeight: "500",
  padding: 0,
  color: theme.palette.background.red,
  fontSize: "16px",
  cursor: "pointer",
  "&:hover": {
    color: "rgb(160, 6, 14)",
  },
}));

const SendOTP = () => {
  const navigate = useNavigate();
  const [otpNo, setOtpNo] = useState("");
  const [isError, setIsError] = useState("");
  const [disableResend, setDisableResend] = useState(false);
  const mobileNumber = useLocation().state?.mobileNumber;
  const { t } = useTranslation("translation", {
    keyPrefix: "editMobileNumber",
  });
  const [count, { startCountdown }] = useCountdown({
    countStart: dayjs().add(5, "minute").unix() - dayjs().unix(),
    intervalMs: 1000,
  });

  const handleSubmit = () => {
    if (otpNo.length >= 6) {
      // setAction("submit");
    } else {
      setIsError(t("It must be a 6-digit number"));
    }
  };

  const onResendOtp = async e => {
    e.preventDefault();
    if (count === 0) {
      setDisableResend(true);
    }
  };

  const handleChangeMobile = e => {
    e.preventDefault();
    navigate("/settings/account-settings/edit-mobile-number");
  };

  useEffectOnce(() => {
    if (!mobileNumber) {
      navigate("/settings/account-settings");
    } else {
      startCountdown();
    }
  }, []);
  return (
    <SplitTopBottomLayout>
      <Helmet>
        <title>{t("Change Mobile Number")}</title>
      </Helmet>
      <Container>
        <Box mt="3rem" mb="3rem" textAlign="center">
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 400,
              fontSize: "1.125rem",
            }}
          >
            {t("We sent a 6-digit code to")}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 400,
              fontSize: "1.125rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            {mobileNumber}
            <ResendBtn data-testid="change-btn" onClick={handleChangeMobile}>
              {t("Change")}
            </ResendBtn>
          </Typography>
        </Box>
        <Box
          mt="2rem"
          mb="2rem"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="15px"
        >
          <OtpField
            value={otpNo}
            setValue={setOtpNo}
            isError={isError !== ""}
          />
          {isError && (
            <Typography
              component="span"
              variant="subtitle2"
              color="error.main"
              sx={{ fontWeight: "400", display: "block", textAlign: "center" }}
            >
              {isError}
            </Typography>
          )}
        </Box>
        <Box mt="2rem" textAlign="center">
          <Typography
            variant="subtitle2"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
              color: "grey.accent2",
              fontWeight: "400",
            }}
          >
            {t("Didn't receive a code?")}

            <>
              <ResendBtn
                data-testid="resend-btn"
                disabled={count > 0 || disableResend}
                onClick={onResendOtp}
                sx={{
                  paddingLeft: 0.5,
                  paddingRight: 0,
                  "&:disabled": { color: "grey.accent2" },
                }}
              >
                {t("Resend Code")}
              </ResendBtn>
              {count > 0 ? (
                <Typography
                  sx={{
                    display: "inline",
                    color: "grey.accent2",
                    fontWeight: "500",
                    marginLeft: 0.5,
                  }}
                >
                  ({dayjs.duration(count * 1000).format("mm:ss")})
                </Typography>
              ) : (
                ""
              )}
            </>
          </Typography>
        </Box>
      </Container>
      <Container
        id="btn-container"
        sx={{
          marginTop: 10,
          backgroundColor: "background.paper",
          width: "100%",
          padding: "0 16px 32px 16px",
        }}
      >
        <Button
          id="next-btn"
          data-testid="next-btn"
          variant="contained"
          fullWidth
          sx={{ height: 48, width: "100%", borderRadius: "24px" }}
          onClick={handleSubmit}
        >
          <Typography variant="button" color="background.paper">
            {t("NEXT")}
          </Typography>
        </Button>
      </Container>
    </SplitTopBottomLayout>
  );
};

export default SendOTP;
