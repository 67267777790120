import React, { useState, useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import useNavigationHook from "../../../../hooks/useNavigation";
import AbcConvertCard from "../../components/convertCoins/abcConvertCard/AbcConvertCard";
import ConversionRate from "../../components/convertCoins/conversionRate/ConversionRate";
import ConvertInputCard from "../../components/convertCoins/convertInputCard/ConvertInputCard";
import {
  fetchAsyncConvertPartnerBalance,
  resetConvertPartnerBalance,
} from "../../redux/slices/convertPartnerCoins/convertPartnerBalance";
import {
  fetchAsyncConvertPartnerRates,
  resetConvertPartnerRates,
} from "../../redux/slices/convertPartnerCoins/convertPartnerRates";
import { Status } from "../../../../redux/constant";
import {
  fetchAsyncConvertPartnerDetail,
  resetConvertPartnerDetail,
} from "../../redux/slices/convertPartnerCoins/convertPartnerDetail";
import { getValueByLanguage } from "../../../../utils/getLanguage";
import { trackStartPointConversion } from "../../../../mixpanel";
import { fetchAsyncCoins, resetCoins } from "../../redux/slices/coins/Coins";
import { getPartnerImageByLang } from "utils/imageAsset";
import { coinFormatter } from "utils/coinFormatter";
import { getChannelConfig } from "config";
import { abcUnit } from "features/wallet/utils/abc";
import KrungsriTncModal from "features/wallet/components/convertCoins/partnerKrungsri/KrungsriTncModal";

const targetId = getChannelConfig().abcCoinSymbol;

const PartnerConvertCoins = () => {
  const { partnerId } = useLocation().state || {};
  const { partnerId: urlPartnerId } = useParams();
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { coins, status: coinsStatus } = useSelector(state => state.coins);
  const { convertPartnerDetail, status: convertPartnerDetailStatus } =
    useSelector(state => state.convertPartnerDetail);
  const {
    convertPartnerBalance,
    status: convertPartnerBalanceStatus,
    errorMessage: convertPartnerBalanceErrorMessage,
  } = useSelector(state => state.convertPartnerBalance);
  const { convertPartnerRates, status: convertPartnerRatesStatus } =
    useSelector(state => state.convertPartnerRates);

  const { t } = useTranslation("translation", {
    keyPrefix: "convertCoins",
  });
  const [values, setValues] = useState({
    balance: "",
  });
  const [error, setError] = useState({
    hasError: false,
    message: "",
  });
  const [openKrungsriTncModal, setOpenKrungsriTncModal] = useState(false);

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    if (!partnerId) {
      navigate(`/convert/${urlPartnerId}`);
    }
  }, [navigate, partnerId, urlPartnerId]);

  useEffect(() => {
    if (partnerId) {
      trackStartPointConversion(partnerId);
    }
  }, [partnerId]);

  useEffect(() => {
    if (!partnerId) return;
    dispatch(fetchAsyncCoins());
    dispatch(fetchAsyncConvertPartnerDetail({ partnerId }));
    dispatch(fetchAsyncConvertPartnerBalance({ partnerId }));
    dispatch(
      fetchAsyncConvertPartnerRates({ source: partnerId, target: targetId }),
    );

    return () => {
      dispatch(resetCoins());
      dispatch(resetConvertPartnerDetail());
      dispatch(resetConvertPartnerBalance());
      dispatch(resetConvertPartnerRates());
    };
  }, [dispatch, partnerId]);

  const isDisableButton = () => {
    return (
      error.hasError ||
      convertPartnerBalanceStatus !== Status.LOADED ||
      convertPartnerRatesStatus !== Status.LOADED
    );
  };

  const getEnterAmountBetweenError = () => {
    return `${t("Please enter amount between")} ${coinFormatter(
      convertPartnerRates.sourceMin.toString(),
    )} - ${coinFormatter(convertPartnerRates.sourceMax.toString())}`;
  };

  const isInvalidEmptyInput = () => {
    if (!values.balance) {
      setError({
        hasError: true,
        message: getEnterAmountBetweenError(),
      });
      return true;
    }
    return false;
  };

  const isInvalidMinMax = () => {
    if (
      convertPartnerRates?.sourceMin === null ||
      typeof convertPartnerRates?.sourceMin === "undefined" ||
      convertPartnerRates?.sourceMax === null ||
      typeof convertPartnerRates?.sourceMax === "undefined"
    ) {
      return false;
    }
    if (
      convertPartnerRates.sourceMin > parseInt(values.balance, 10) ||
      convertPartnerRates.sourceMax < parseInt(values.balance, 10)
    ) {
      setError({
        hasError: true,
        message: getEnterAmountBetweenError(),
      });
      return true;
    }
    return false;
  };

  const isInvalidExceedBalance = () => {
    if (convertPartnerBalance?.balance < parseInt(values.balance)) {
      setError({
        hasError: true,
        message: t("Your point is insufficient"),
      });
      return true;
    }
    return false;
  };

  const isInvalidInput = () => {
    return (
      isInvalidEmptyInput() || isInvalidExceedBalance() || isInvalidMinMax()
    );
  };

  const handleClick = () => {
    if (isInvalidInput()) return;

    navigate(`/partner-review/${partnerId}`, {
      state: {
        partnerId,
        targetId,
        partner: {
          name: convertPartnerDetail.name,
        },
        rate: {
          rate: convertPartnerRates.value,
          fromUnit: convertPartnerDetail.unitName,
          toUnit: abcUnit,
        },
        convertPoints: values.balance.toString(),
        convertedCoins: (values.balance / convertPartnerRates.value).toString(),
      },
    });
  };

  if (!partnerId) {
    return null;
  }

  return (
    <Box>
      <Helmet id="lbl_title_ConvertLoyaltyPointPage">
        <title>{t("Convert")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "32px 16px 0",
          backgroundColor: "grey.accent4",
          height: "calc(100vh - 50px)",
        }}
        data-testid="partner-convert-coins"
      >
        <Box>
          <ConvertInputCard
            partnerBalanceStatus={convertPartnerBalanceStatus}
            partnerNameStatus={convertPartnerDetailStatus}
            partnerName={getValueByLanguage(convertPartnerDetail?.name)}
            partnerBalance={convertPartnerBalance?.balance}
            partnerBalanceUnit={getValueByLanguage(
              convertPartnerDetail?.unitName,
            )}
            partnerBalanceErrorMessage={convertPartnerBalanceErrorMessage}
            logo={getPartnerImageByLang(partnerId)}
            min={convertPartnerRates?.sourceMin}
            max={convertPartnerRates?.sourceMax}
            values={values}
            setValues={setValues}
            error={error}
            setError={setError}
            dropdownOption={convertPartnerRates?.amountOptions}
          />
          <ConversionRate
            status={convertPartnerRatesStatus}
            rate={convertPartnerRates.value}
            rateFrom={getValueByLanguage(convertPartnerDetail?.unitName)}
            rateTo={abcUnit}
          />
          <AbcConvertCard
            convertBalance={values.balance}
            remainingCoinsBalance={coins?.balance}
            rate={convertPartnerRates.value}
            rateStatus={convertPartnerRatesStatus}
            coinsStatus={coinsStatus}
          />
          {(partnerId === "krungsri" || partnerId === "firstchoice") && (
            <Box sx={{ mt: "24px" }}>
              <Typography variant="body2">
                {`${t(
                  "Before exchanging, please thoroughly read and understand all",
                )} `}
                <Typography
                  variant="body2"
                  component={"a"}
                  onClick={() => setOpenKrungsriTncModal(true)}
                  sx={{ color: "secondary.blue.accent5", cursor: "pointer" }}
                >
                  {t("Terms & conditions.")}
                </Typography>
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ p: "32px 0" }}>
          <Button
            id="review"
            variant="contained"
            fullWidth
            sx={{ height: 48, width: "100%", borderRadius: "24px" }}
            disabled={isDisableButton()}
            onClick={handleClick}
          >
            <Typography variant="button" color="background.paper">
              {t("Review")}
            </Typography>
          </Button>
        </Box>
      </Box>
      <KrungsriTncModal
        isVisible={openKrungsriTncModal}
        onClose={() => setOpenKrungsriTncModal(false)}
      />
    </Box>
  );
};
export default PartnerConvertCoins;
