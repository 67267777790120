import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import useTranslation from "../../../../hooks/useTranslation";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { ReactComponent as DashedDivider } from "../../../../assets/images/DashedDivider.svg";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import parse from "html-react-parser";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/CopyVoucher.svg";
import useNavigationHook from "../../../../hooks/useNavigation";
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import { formatDateTime } from "../../../../utils/dateTimeFormatter";

const VoucherDetail = () => {
  const { deal, code } = useLocation().state;
  const { t } = useTranslation("translation", { keyPrefix: "voucherDetail" });

  const setActiveMenu = useNavigationHook();
  const language = localStorage.getItem("lang");

  const toaster = () => {
    toast.remove();
    toast(t("Voucher Code Copied"), {
      style: {
        textAlign: "start",
        padding: "12px, 16px, 12px, 16px",
        color: "#FFFFFF",
        backgroundColor: "#141312",
        width: "100%",
        borderRadius: "16px",
      },
    });
  };

  React.useEffect(() => {
    setActiveMenu(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Voucher Details")}</title>
      </Helmet>
      <Box
        sx={{
          p: "32px 20px 0px 20px",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            position: "relative",
            bottom: "0px",
          }}
        >
          <Box
            component="img"
            sx={{
              height: { xs: "128px", sm: "256px" },
              width: "100%",
              objectFit: "cover",
              borderRadius: "16px",
              border: "solid 1px",
              borderColor: "grey.accent3",
              borderBottom: "0px",
            }}
            src={deal.bgPicture}
          />
          <Box
            sx={{
              height: "22px",
              width: "22px",
              borderRadius: "50%",
              position: "absolute",
              borderRight: "1px solid #000000",
              bottom: "-12px",
              left: "-10px",
              border: "1px solid",
              borderColor: "grey.accent3",
              borderLeft: "1px solid transparent",
              borderBottom: "1px solid transparent",
              backgroundColor: "white",
              transform: "rotate(45deg)",
            }}
          />
          <Box
            sx={{
              height: "22px",
              width: "22px",
              borderRadius: "100%",
              position: "absolute",
              bottom: "-12px",
              right: "-10px",
              border: "1px solid",
              borderColor: "grey.accent3",
              borderRight: "1px solid transparent",
              borderTop: "1px solid transparent",
              backgroundColor: "white",
              transform: "rotate(45deg)",
            }}
          ></Box>
        </Box>
        <Stack
          sx={{
            width: "100%",
            background: "background.paper",
            border: "1px solid",
            borderColor: "grey.accent3",
            borderRadius: "16px",
            p: "0px 16px 24px 16px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src={deal.merchant.logo}
            sx={{
              position: "relative",
              top: "-25px",
              marginBottom: "-15px",
              width: "50px",
              height: "50px",
              borderRadius: "50px",
              border: "1px solid",
              borderColor: "grey.accent3",
              objectFit: "cover",
            }}
          />
          <Typography variant="body2" sx={{ marginBottom: "16px" }}>
            {deal.merchant.name}
          </Typography>
          <Barcode
            height={64}
            value={code}
            text={"ABC Wallet"}
            font={
              language === "en" || language === "en-US"
                ? "Roboto"
                : '"Anuyart", "Noto Serif Thai", "sans-serif"'
            }
            textMargin={-1}
            fontSize={14}
            marginBottom={24}
          />
          <QRCode value={code} size={136} renderAs="svg" />
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              mt: "16px",
            }}
          >
            {deal.title}
          </Typography>
          <Typography
            variant="helpingText"
            color="grey.accent2"
            sx={{
              textAlign: "center",
              mt: "8px",
            }}
          >
            {`${t("Campaign Period")}: ${formatDateTime(
              deal.startDateTime,
              "D MMM YYYY",
            )} ${"To"} ${formatDateTime(deal.endDateTime, "D MMM YYYY")}`}
          </Typography>
          <DashedDivider width="100%" style={{ padding: "24px 0 24px 0" }} />
          <Button
            id="copy"
            variant="outlined"
            sx={{ padding: "8px 16px", borderRadius: "28px" }}
            className="btn"
            data-clipboard-text={code}
            onClick={() => toaster()}
          >
            {code}
            <CopyIcon style={{ position: "relative", left: "5px" }} />
          </Button>
        </Stack>
        <Box
          sx={{
            width: "100%",
            background: "background.paper",
            border: "1px solid",
            borderColor: "grey.accent3",
            borderRadius: "16px",
            p: 2,
            mt: "24px",
          }}
        >
          <Typography variant="subtitle">{deal.tagline}</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            {deal.desc}
          </Typography>
        </Box>
        <Box
          sx={{
            background: "background.paper",
            border: "1px solid",
            borderColor: "grey.accent3",
            borderRadius: "16px",
            p: 2,
            my: "24px",
          }}
        >
          <Typography variant="subtitle">{t("Terms & Conditions")}</Typography>
          <Typography
            variant="body1"
            sx={{ mt: 1, mb: 1, color: "grey.accent2" }}
          >
            {parse(deal.tnc.replace(/(\r\n|\r|\n)/g, "<br>"))}
          </Typography>
        </Box>
        <Toaster position="bottom-center" reverseOrder={false}>
          {t => (
            // Custimize default toast
            <ToastBar toast={t}>
              {({ message }) => (
                <>
                  <div>{message}</div>
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
      </Box>
    </>
  );
};

export default VoucherDetail;
