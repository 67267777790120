import React, { useEffect, useState } from "react";
import moment from "moment";
import thLocale from "moment/locale/th";
import { useLocation, useRoutes } from "react-router-dom";
import useKeycloakSwitch from "channel/useKeycloakSwitch";
import VConsole from "vconsole";
import Routes from "./routes";
import useAuth from "./hooks/useAuth";
import { CreateCustomTheme } from "./theme";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useSettings from "./hooks/useSettings";
import SplashScreen from "./components/splashScreen/SplashScreen";
import { authStatus } from "./contexts/authContext/AuthContext";
import NavBar from "./components/navigation/NavBar";
import { DealsProvider } from "./contexts/dealsContext";
import { LoadingScreenProvider } from "./contexts/LoadingScreenContext";
import ProgressScreen from "./components/progressScreen/ProgressScreen";
import useTranslation from "./hooks/useTranslation";
import { debugFlag, getChannelConfig, growthbook, apiEndpoint } from "./config";
import { Box, Container, Drawer, Toolbar, useMediaQuery } from "@mui/material";
import { DropdownLanguageProvider } from "./contexts/DropdownLanguageContext";
import { NavigationProvider } from "contexts/NavigationContext";
import Menu from "components/navigation/Menu";
import GrowthbookAPI from "./apiConfig/GrowthbookAPI";
import Settings from "pages/settings/Settings";
import { DrawerMenuProvider } from "contexts/DrawerMenuContext";
import { EarthNavbarProvider } from "contexts/EarthNavbarContext";

export const getPathnameLevel = pathname => {
  switch (pathname) {
    case "/":
      return 1;
    case "/wallet":
      return 1;
    case "/wallet/nfts":
      return 1;
    case "/marketplace":
      return 1;
    case "/marketplace/my-vouchers":
      return 1;
    case "/wallet/nfts/other-collectibles":
      return 1;
    default:
      return 2;
  }
};

export const MENU_WIDTH = "88px";

const App = () => {
  const { keycloak } = useKeycloakSwitch();
  const { initializeTranslation } = useTranslation();
  const content = useRoutes(Routes(keycloak));
  const { settings } = useSettings();
  const auth = useAuth();
  const cardSelected = React.useRef(false);
  const merchantSelected = React.useRef(false);
  const merchantScrollLeft = React.useRef(0);
  const [open, setOpen] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState(true);
  const [earthNavbar, setEarthNavbar] = React.useState(null);
  const utheme = useTheme();
  const matchSM = useMediaQuery(utheme.breakpoints.only("xs"));
  const matchUPSM = useMediaQuery(utheme.breakpoints.up("sm"));
  const matchDownMD = useMediaQuery(utheme.breakpoints.down("md"));
  const [activeDropdownLanguage, setActiveDropdownLanguage] =
    React.useState(false);
  const [activeDrawerMenu, setActiveDrawerMenu] = React.useState(false);

  const [templateArea, setTemplateArea] = React.useState("");
  const [templateColumn, setTemplateColumn] = React.useState("");
  const { pathname } = useLocation();
  const theme = CreateCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  const [level, setLevel] = useState(0);
  const initializeFeatureFlags = async () => {
    try {
      const response = await GrowthbookAPI.get(
        apiEndpoint.growthbookFeatureFlags,
      );
      growthbook.setFeatures(response.data.features);
      growthbook.setAttributes({
        channel: getChannelConfig().channel,
      });
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  useEffect(() => {
    initializeTranslation();
    initializeFeatureFlags();
    const lang = localStorage.getItem("lang");
    if (lang === "th") {
      moment.updateLocale("th", [thLocale]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let vConsole;
    if (debugFlag.enableDebugTool) {
      vConsole = new VConsole({ theme: "dark" });
    }
    return () => {
      if (vConsole) {
        vConsole.destroy();
      }
    };
  }, []);

  useEffect(() => {
    const level = getPathnameLevel(pathname);
    setLevel(level);
  }, [pathname]);

  useEffect(() => {
    if (level >= 1 && matchUPSM && activeMenu) {
      setTemplateArea(`
      "menu navbar"
      "menu content"
      `);
      setTemplateColumn(`${MENU_WIDTH} 1fr`);
      return;
    }
    setTemplateArea(`
    "navbar"
    "content"
    `);
    setTemplateColumn("1fr");
  }, [level, matchUPSM, activeMenu]);

  return (
    <ThemeProvider theme={theme}>
      <LoadingScreenProvider
        value={{
          setOpen,
        }}
      >
        <DealsProvider
          value={{
            merchantSelected,
            cardSelected,
            merchantScrollLeft,
          }}
        >
          <CssBaseline />
          <ProgressScreen open={open} />
          {auth.status === authStatus.INITIAL ? (
            <SplashScreen />
          ) : (
            <>
              <DropdownLanguageProvider value={setActiveDropdownLanguage}>
                <NavigationProvider value={setActiveMenu}>
                  <EarthNavbarProvider value={setEarthNavbar}>
                    <DrawerMenuProvider value={setActiveDrawerMenu}>
                      <Box
                        sx={{
                          height: "100vh",
                          display: {
                            xs: "block",
                            sm: "block",
                            md: "grid",
                            lg: "grid",
                            xl: "grid",
                          },
                          gridTemplateAreas: {
                            md: templateArea,
                            lg: templateArea,
                            xl: templateArea,
                          },
                          gridTemplateColumns: templateColumn,
                          gridTemplateRows: "60px 1fr",
                        }}
                      >
                        <NavBar
                          activeDropdownLanguage={activeDropdownLanguage}
                          activeMenu={activeMenu}
                          level={level}
                          activeDrawerMenu={activeDrawerMenu}
                          keycloak={keycloak}
                          earthNavbar={earthNavbar}
                        />

                        <Menu
                          active={activeMenu}
                          level={level}
                          activeDrawerMenu={activeDrawerMenu}
                        />

                        {earthNavbar &&
                        (pathname === "/onboarding" || pathname === "/") ? (
                          <Box id="app-box">{content}</Box>
                        ) : (
                          <>
                            <Drawer
                              anchor={matchDownMD ? "right" : "left"}
                              variant="persistent"
                              sx={{
                                left: MENU_WIDTH,
                                [`& .MuiDrawer-paper`]: {
                                  left: {
                                    xs: 0,
                                    sm: 0,
                                    md: MENU_WIDTH,
                                    lg: MENU_WIDTH,
                                    xl: MENU_WIDTH,
                                  },
                                  boxSizing: "border-box",
                                  zIndex: 100,
                                  width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "375px",
                                    lg: "375px",
                                    xl: "375px",
                                  },
                                },
                              }}
                              open={activeDrawerMenu}
                            >
                              <Toolbar />
                              <Settings level={level} />
                            </Drawer>
                            <Container
                              id="app-container"
                              sx={{
                                gridArea: "content",
                                overflowY: "auto",
                              }}
                              disableGutters={matchSM}
                            >
                              {content}
                            </Container>
                          </>
                        )}
                      </Box>
                    </DrawerMenuProvider>
                  </EarthNavbarProvider>
                </NavigationProvider>
              </DropdownLanguageProvider>
            </>
          )}
        </DealsProvider>
      </LoadingScreenProvider>
    </ThemeProvider>
  );
};

export default App;
