import axios from "axios";
import { getChannelConfig } from "../../config";
import jwtDecode from "jwt-decode";
import AuthContextServices from "../../contexts/authContext/AuthContextServices";
import { history } from "../../history";
import { authConfig, apiEndpoint } from "../../config"

const refreshInterceptor = async config => {
  const keycloakLogin = getChannelConfig().keycloakLogin;
  const token = AuthContextServices.getTokenFromLocalStorage();
  const { access_token, refresh_token } = JSON.parse(token);

  const { exp } = jwtDecode(access_token);
  const now = Math.floor(Date.now() / 1000);

  const isExpired = now > exp;

  if (keycloakLogin && isExpired) {
    history.push("/auth-error");
    return;
  }

  if (!keycloakLogin && isExpired) {
    try {
      const response = await axios.post(
        `${apiEndpoint.keycloakProtocol}/openid-connect/token`,
        new URLSearchParams({
          client_id: authConfig.client_id,
          refresh_token: refresh_token,
          grant_type: "refresh_token",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      AuthContextServices.setLocalStorageToken(JSON.stringify(response.data));

      config.headers["Authorization"] = `Bearer ${response.data.access_token}`;
      config.headers["Accept-language"] = localStorage.getItem("lang") ?? "en";
      return config;
    } catch (error) {
      history.push("/auth-error");
      return;
    }
  }

  let language = localStorage.getItem("lang");
  language === "en-US" && (language = "en");
  config.headers["Accept-language"] = language;
  config.headers["Authorization"] = `Bearer ${access_token}`;
  return config;
};

export default refreshInterceptor;
